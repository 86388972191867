import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ContactFormComponent} from './components/contact-form/contact-form.component';
import {PropertyFilterPipe} from './pipes/property-filter.pipe';
import {ValidationHelperService} from './services/validation-helper.service';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {TabViewModule} from 'primeng/tabview';
import {ProgressBarModule} from 'primeng/progressbar';
import {TableModule} from 'primeng/table';
import {DialogComponent} from './components/dialog/dialog.component';
import {MessagesComponent} from './components/messages/messages.component';
import {ProfileManagerComponent} from './components/profile-manager/profile-manager.component';
import {ButtonModule} from 'primeng/button';
import {SpinnerButtonComponent} from './components/spinner-button/spinner-button.component';
import {MessageService} from 'primeng/api';
import {TooltipComponent} from './components/tooltip/tooltip.component';
import {TooltippedDirective} from './directives/tooltipped.directive';
import {TooltipModalComponent} from './components/tooltip-modal/tooltip-modal.component';
import {PanelBodyComponent} from './components/panel/panel-body/panel-body.component';
import {PanelHeaderComponent} from './components/panel/panel-header/panel-header.component';
import {PanelLayoutComponent} from './components/panel/panel-layout/panel-layout.component';
import {InputTextModule} from 'primeng/inputtext';
import {RolesAllowedDirective} from './directives/roles-allowed.directive';
import {FileInputComponent} from './components/file-input/file-input.component';
import {SpecialCharPipe} from './pipes/special-char.pipe';
import {NgxFileDropModule} from 'ngx-file-drop';
import {DottedTextAreaDirective} from './directives/dotted-text-area.directive';
import {AvatarComponent} from './components/avatar/avatar.component';
import {ImageCropperModule} from 'ngx-image-cropper';
import {CustomCropperComponent} from './components/custom-cropper/custom-cropper.component';
import {FileDropComponent} from './components/file-drop/file-drop.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ProgressBarModule,
    ButtonModule,
    TabViewModule,
    TableModule,
    InputTextModule,
    ReactiveFormsModule,
    NgxFileDropModule,
    ImageCropperModule,
  ],
  declarations: [
    ContactFormComponent,
    DialogComponent,
    PropertyFilterPipe,
    MessagesComponent,
    ProfileManagerComponent,
    SpinnerButtonComponent,
    TooltipComponent,
    TooltippedDirective,
    TooltipModalComponent,
    PanelBodyComponent,
    PanelHeaderComponent,
    PanelLayoutComponent,
    RolesAllowedDirective,
    FileInputComponent,
    SpecialCharPipe,
    DottedTextAreaDirective,
    AvatarComponent,
    CustomCropperComponent,
    FileDropComponent,
  ],
  providers: [
    ValidationHelperService,
    MessageService
  ],
  exports: [
    ContactFormComponent,
    DialogComponent,
    MessagesComponent,
    PropertyFilterPipe,
    ProfileManagerComponent,
    SpinnerButtonComponent,
    TooltipComponent,
    TooltipModalComponent,
    TooltippedDirective,
    PanelBodyComponent,
    PanelHeaderComponent,
    PanelLayoutComponent,
    RolesAllowedDirective,
    FileInputComponent,
    SpecialCharPipe,
    DottedTextAreaDirective,
    AvatarComponent,
    FileDropComponent,
    CustomCropperComponent,
  ]
})
export class CommonsModule {
}
