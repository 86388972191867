import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import * as infrastructureActions from '../infrastructure/infrastructure.actions';
import {catchError, map, switchMap} from 'rxjs/operators';
import {of} from 'rxjs';
import {InfrastructureService} from '../../submodules/logistics/services/infrastructure.service';
import {Infrastructure} from '../../model/infrastructure';

@Injectable()
export class InfrastructuresEffects {
  fetchInfrastructures$ = createEffect(() =>
    this.actions$.pipe(
      ofType(infrastructureActions.fetchInfrastructures),
      switchMap(() => this.infrastructureService.getInfrastructureElements().pipe(
        map((infrastructures: Infrastructure[]) => infrastructureActions.fetchInfrastructuresSuccess({infrastructures: infrastructures})),
        catchError(() => of(infrastructureActions.fetchInfrastructuresError()))
      ))
    )
  );

  constructor(private actions$: Actions,
              private infrastructureService: InfrastructureService) {
  }
}
