import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';

@Component({
  selector: 'app-file-input',
  templateUrl: './file-input.component.html',
  styleUrls: ['./file-input.component.less']
})
export class FileInputComponent implements OnInit {
  @ViewChild('fileInput') fileInput;

  @Input() labelText;
  @Input() accept = '*';

  @Input() displayLink = false;
  @Input() downloadLinkLabel = 'File';
  @Input() disabled = false;
  @Input() disabledDeleteButton = false;

  @Output() fileDeleted = new EventEmitter<any>();

  file: File;
  public displayError = false;

  constructor() {
  }

  ngOnInit(): void {
  }

  handleFileSelection(files) {
    if (!files[0]) {
      return;
    }

    this.displayError = false;

    this.file = files[0];
    const reader = new FileReader();
    reader.readAsDataURL(files[0]);
  }

  deleteFile() {
    this.file = null;
    this.fileInput.nativeElement.value = '';
    this.fileDeleted.emit();
  }

  displayFileInput() {
    setTimeout(() => this.displayLink = false);
  }

}
