import * as moment from 'moment';

export interface ReportFilter {
  reportType: number;
  name: string;
  creationDate: Date;
}

export class ReportFilterParams {
  reportType = null;
  name = '';
  creationDate = new Date();

  constructor(filter?: ReportFilter) {
    if (!!filter) {
      for (const prop of Object.keys(filter)) {
        this[prop] = filter[prop];
      }
    }
  }

  public createFilterRequestParams(): string {
    const tableState = sessionStorage.getItem('reports-table-state');
    const {
      first = 0,
      rows = 25
    } = tableState ? JSON.parse(tableState) : {};
    const properties = [
      {field: 'reportType', formatter: (value) => value},
      {field: 'name', formatter: (value) => value},
      {field: 'creationDate', formatter: (value) => moment(value).format('YYYY-MM-DD')},
    ];

    let queryString = '?';
    for (let property of properties) {
      const value = this[property.field];
      if (value) {
        queryString += `${property.field}=${property.formatter(value)}&`;
      }
    }
    queryString += `offset=${first}&`;
    queryString += `limit=${rows}`;
    return queryString;

  }
}
