import {createReducer, on} from '@ngrx/store';
import * as reportActions from '../reports/reports.actions';
import {createEntityAdapter, EntityState} from '@ngrx/entity';
import {compareReports, Report} from '../../model/report';

export interface ReportsState extends EntityState<Report> {
  allReportsLoaded: boolean;
  totalElements: number;
}

export const adapter = createEntityAdapter<Report>({
  sortComparer: compareReports
});

export const initialReportState = adapter.getInitialState({
  allReportsLoaded: false,
  totalElements: 0
});

export const reportReducer = createReducer(
  initialReportState,
  on(reportActions.fetchReportsSuccess,
    (state, action) => adapter.setAll(action.reports,
      {
        ...state,
        totalElements: action.totalElements,
        allReportsLoaded: true
      })),
  on(reportActions.addReportsSuccess,
    (state, action) => adapter.addMany(action.reports,
      {
        ...state,
        totalElements: state.totalElements + action.totalElements
      })),
  on(reportActions.deleteReportsSuccess,
    (state, action) => adapter.removeOne(action.index,
      {...state}))
);
