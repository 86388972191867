export interface Report {
  id: number;
  name: string;
  creationDate: Date;
  reportType: string;
  creatorId: string;
  size: string;
  extension: string;
}

export function compareReports(report1: Report, report2: Report) {
  const compare = new Date(report1.creationDate).getTime() - new Date(report2.creationDate).getTime();
  return compare < 0 ? 1 : compare > 0 ? -1 : 0;
}
