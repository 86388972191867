import {Actions, createEffect, ofType} from '@ngrx/effects';
import * as resultActions from '../results/results.actions';
import {catchError, map, switchMap} from 'rxjs/operators';
import {Result} from '../../model/result';
import {of} from 'rxjs';
import {Injectable} from '@angular/core';
import {TravelService} from '../../submodules/logistics/services/travel.service';

@Injectable()
export class ResultsEffects {
  fetchResults$ = createEffect(() =>
    this.actions$.pipe(
      ofType(resultActions.fetchResults),
      switchMap(() => this.travelService.getResults().pipe(
        map((results: Result[]) => resultActions.fetchResultsSuccess({results: results})),
        catchError(() => of(resultActions.fetchResultsError()))
      ))
    )
  );

  constructor(private actions$: Actions,
              private travelService: TravelService) {
  }
}
