import {Component, HostListener, OnInit} from '@angular/core';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {Router} from '@angular/router';

declare const $: any;

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.less'],
  animations: [
    trigger('scrollAnimation', [
      state('transparent', style({
        'box-shadow': 'none',
        'background-color': 'rgba(255,255,255,0.6)',
        'border-bottom': '1px solid #dfdfdf'
      })),
      state('solid', style({
        'background-color': 'rgb(1, 133, 179)',
        'border-bottom': '1px solid rgb(1, 133, 179)',
        'text-shadow': 'none'
      })),
      transition('transparent => solid', animate('700ms ease-out')),
      transition('solid => transparent', animate('700ms ease-in'))
    ]),

    trigger('emerge', [
      state('visible', style({opacity: 1})),
      state('hidden', style({opacity: 0})),
      transition('hidden => visible', animate('900ms ease-out'))
    ])

  ]
})
export class LandingComponent implements OnInit {

  sessionExpired = false;
  navbarState = 'transparent';
  navbarCollapsed = true;
  scrolledToTop = true;
  slides: { [id: string]: string };

  constructor(private router: Router) {
    this.slides = {};
  }

  ngOnInit() {
    window.setTimeout(() => this.checkScroll(), 500);
    this.router.navigateByUrl('/app');
  }

  expandNavbar() {
    this.navbarCollapsed = !this.navbarCollapsed;
    this.toggleNavbarState();
  }

  toggleNavbarState() {
    if (!this.scrolledToTop || !this.navbarCollapsed) {
      this.navbarState = 'solid';
    } else {
      this.navbarState = 'transparent';
    }
  }

  @HostListener('window:scroll', ['$event'])
  checkScroll() {
    const scrollPosition = window.pageYOffset;
    this.scrolledToTop = scrollPosition < 30;

    this.toggleNavbarState();

    const windowHeight = $(window).height();
    const padding = 50;

    for (const id in this.slides) {
      if ($(id).length === 1) {
        const elementOffset = $(id).offset().top;
        const visibleHeight = windowHeight - (elementOffset - scrollPosition);

        if (visibleHeight - padding > windowHeight / 6) {
          this.slides[id] = 'visible';
          const $spiedElement = $(`[data-scroll-spy="${id}"]`);
          if ($spiedElement.length > 0) {
            $('.active[data-scroll-spy]').removeClass('active');
            $spiedElement.addClass('active');
          }
        }
      }
    }
  }
}
