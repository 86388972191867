import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Vehicle} from '../../../model/vehicle';

@Injectable({
  providedIn: 'root'
})
export class VehicleService {
  vehiclePath = '/api/suc/cbd/vehicles';

  constructor(private http: HttpClient) {
  }

  public getActiveVehicles(): Observable<Vehicle[]> {
    return this.http.get<Vehicle[]>(`${this.vehiclePath}`);
  }
}
