import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Warning, WarningType} from '../../../model/warning';
import {WarningFilter, WarningFilterParams} from '../../../model/warning-filter';
import {map, tap} from 'rxjs/operators';
import {WarningsWrapper} from '../../../model/warnings-wrapper';
import * as warningActions from '../../../store/warnings/warnings.actions';
import {Store} from '@ngrx/store';
import {AppState} from '../../../store/app.reducer';

@Injectable({
  providedIn: 'root'
})
export class WarningService {
  warningPath = '/api/suc/cbd/warnings';

  constructor(private http: HttpClient,
              private store: Store<AppState>) {
  }

  public getWarnings(type: WarningType, warningFilter?: WarningFilter): Observable<WarningsWrapper> {
    const warningFilterParams = new WarningFilterParams(warningFilter);
    return this.http.get<WarningsWrapper>(`${this.warningPath}/${type}` + warningFilterParams.createFilterRequestParams()).pipe(
      map(res => ({
        ...res,
        warnings: res.warnings.map(warning => ({
          ...warning
        }))
      }))
    );
  }


  public getAllWarnings(): Observable<Warning[]> {
    return this.http.get<Warning[]>(`${this.warningPath}`);
  }

  public getWarning(type: WarningType, id: number): Observable<Warning> {
    return this.http.get<Warning>(`${this.warningPath}/${type}/${id}`);
  }

  public saveWarning(warning: Warning): Observable<Warning> {
    return this.http.post<Warning>(`${this.warningPath}/${warning.type}`, warning);
  }

  public updateWarning(warning: Warning): Observable<Warning> {
    return this.http.put<Warning>(`${this.warningPath}/${warning.type}`, warning);
  }

  public deleteWarning(type: WarningType, id: number): Observable<any> {
    return this.http.delete(`${this.warningPath}/${type}/${id}`);
  }

  public createWarningCommon(warning): Observable<Warning> {
    return this.saveWarning(warning).pipe(
      tap(w => this.store.dispatch(warningActions.addWarningSuccess({warning: w, warningType: warning.type})))
    );
  }

  public deleteWarningCommon(type, id): Observable<Warning> {
    return this.deleteWarning(type, id).pipe(
      tap(() => this.store.dispatch(warningActions.deleteWarningSuccess({
        index: id,
        warningType: type
      })))
    );
  }
}
