import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Train} from '../../../model/train';

@Injectable({
  providedIn: 'root'
})
export class TrainService {
  trainPath = '/api/suc/cbd/trains';

  constructor(private http: HttpClient) {
  }

  getTrains(): Observable<Train[]> {
    return this.http.get<Train[]>(this.trainPath);
  }

  saveTrain(train: Train): Observable<Train> {
    return this.http.post<Train>(`${this.trainPath}`, train);
  }

  editTrain(train: Train): Observable<Train> {
    return this.http.put<Train>(`${this.trainPath}`, train);
  }

  deleteTrain(id: number): Observable<any> {
    return this.http.delete(`${this.trainPath}/${id}`);
  }
}
