import {createAction, props} from '@ngrx/store';
import {Message} from '../../model/message';
import {MessageFilter} from '../../model/message-filter';

export const fetchMessages = createAction('[Messages] Fetch Message');
export const fetchMessagesWithFiltering = createAction('[Messages] Fetch Messages With Filtering', props<{ filter: MessageFilter }>());
export const fetchMessagesError = createAction('[Messages] Fetch Message Error');
export const fetchMessagesSuccess = createAction(
  '[Messages] Fetch Messages Success',
  props<{ messages: Message[], totalElements: number }>()
);
export const addMessagesSuccess = createAction(
  '[Messages] Add Message Success',
  props<{ message: Message }>()
);
export const updateMessagesSuccess = createAction(
  '[Messages] Update Message Success',
  props<{ message: Message }>()
);
export const deleteMessagesSuccess = createAction(
  '[Messages] Delete Message Success',
  props<{ index: number }>()
);
