import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import * as avatarActions from '../avatar/avatar.actions';
import {catchError, map, switchMap} from 'rxjs/operators';
import {UserService} from '../../submodules/logistics/services/user.service';
import {SafeUrl} from '@angular/platform-browser';
import {of} from 'rxjs';

@Injectable()
export class AvatarEffects {

  fetchAvatarImage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(avatarActions.fetchAvatarImage),
      switchMap(({avatarUrl}) => {
        return this.userService.getSecureImage(avatarUrl).pipe(
          map((res: SafeUrl) => avatarActions.fetchAvatarImageSuccess({safeAvatarUrl: res})),
          catchError(() => of(avatarActions.fetchAvatarImageError()))
        );
      })
    )
  );


  constructor(
    private actions$: Actions,
    private userService: UserService
  ) {
  }

}
