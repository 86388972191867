import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-panel-header',
  templateUrl: './panel-header.component.html',
  styleUrls: ['./panel-header.component.less']
})
export class PanelHeaderComponent implements OnInit {
  @Input() headerText = '';
  @Input() isEditComponent: boolean;
  @Input() isLeftPanel = true;

  constructor() {
  }

  ngOnInit(): void {
  }

}
