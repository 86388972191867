import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import 'rxjs-compat/add/operator/finally';
import 'rxjs-compat/add/operator/publishReplay';
import {KeycloakService} from 'keycloak-angular';
import {Observable} from 'rxjs';
import {KeycloakUser} from '../../../model/keycloak-user';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private USER_INFO_KEY = 'me';
  private usersPath = '/api/suc/users/';

  constructor(private httpClient: HttpClient,
              private keycloakService: KeycloakService) {
  }

  getUsers(): Observable<KeycloakUser[]> {
    return this.httpClient.get<KeycloakUser[]>(this.usersPath);
  }

  deleteUser(id: string) {
    return this.httpClient.delete(this.usersPath + id);
  }

  createUser(userData: any) {
    return this.httpClient.post(this.usersPath, userData);
  }

  updateUser(id: string, userData: any) {
    return this.httpClient.put(this.usersPath + id, userData).do(v => {
      return v;
    });
  }

  updateCurrentUser(userData: any) {
    return this.httpClient.put(this.usersPath + this.USER_INFO_KEY, userData);
  }

  checkIfContainsRoles(roles: string[]) {
    const userRoles = this.keycloakService.getUserRoles();
    for (const role of roles) {
      if (userRoles.indexOf(role) !== -1) {
        return true;
      }
    }
    return false;
  }
}
