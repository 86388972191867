import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Carrier} from "../../../model/carrier";
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class CarrierService {

  carrierPath = '/api/suc/carriers';

  constructor(private httpClient: HttpClient) {
  }


  getCarriers(): Observable<Carrier[]> {
    return this.httpClient.get<Carrier[]>(`${this.carrierPath}`);
  }

  saveCarrier(carrier: Carrier): Observable<Carrier> {
    return this.httpClient.post<Carrier>(`${this.carrierPath}`, carrier);
  }

}
