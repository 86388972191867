import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class HeartbeatService {

  constructor(private httpClient: HttpClient) {
  }

  ping() {
    return this.httpClient.get('/api/suc/ping', {responseType: 'text'});
  }
}
