import {createAction, props} from '@ngrx/store';
import {Update} from '../../model/update';

export const fetchUpdates = createAction('[Updates] Fetch Update');
export const fetchUpdatesError = createAction('[Updates] Fetch Update Error');
export const fetchUpdatesSuccess = createAction(
  '[Updates] Fetch Updates Success',
  props<{ updates: Update[] }>()
);

export const addUpdateSuccess = createAction(
  '[Updates] Add Update Success',
  props<{ update: Update }>()
);

export const deleteUpdateSuccess = createAction(
  '[Updates] Delete Updates Success',
  props<{ index: number }>()
);
