import {createEntityAdapter, EntityState} from '@ngrx/entity';
import {createReducer, on} from '@ngrx/store';
import {Travel} from '../../model/travel';
import * as travelActions from '../travels/travels.actions';

export interface TravelState extends EntityState<Travel> {
  allTravelsLoaded: boolean;
}

export const adapter = createEntityAdapter<Travel>({
  selectId: (travel: Travel) => travel.blueScore,
  sortComparer: null
});


export const initialTravelState = adapter.getInitialState({
  allTravelsLoaded: false
});

export const travelReducer = createReducer(
  initialTravelState,

  on(travelActions.fetchTravelsSuccess,
    (state, action) => adapter.setAll(action.travels,
      {
        ...state,
        allTravelsLoaded: true
      }))
);

export const {
  selectAll
} = adapter.getSelectors();
