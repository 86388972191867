import {Action, createFeatureSelector, createReducer, createSelector, on} from '@ngrx/store';
import {SafeUrl} from '@angular/platform-browser';
import * as avatarActions from '../avatar/avatar.actions';

export interface AvatarState {
  safeAvatarUrl: SafeUrl;
}

const initialState: AvatarState = {
  safeAvatarUrl: null
};

export const _avatarReducer = createReducer(
  initialState,
  on(avatarActions.fetchAvatarImageSuccess,
    (state, {safeAvatarUrl}) => ({
      ...state,
      safeAvatarUrl: safeAvatarUrl
    }))
);


export function avatarReducer(state: AvatarState, action: Action) {
  return _avatarReducer(state, action);
}

const selectAvatarState = createFeatureSelector<AvatarState>('avatar');
export const getAvatarState = createSelector(selectAvatarState, (state: AvatarState) => state);
