import {Directive, ElementRef, EventEmitter, HostListener, Input, OnInit, Output} from '@angular/core';

@Directive({
  selector: '[appDottedTextArea]'
})
export class DottedTextAreaDirective implements OnInit {

  char = '';

  @Input() set appDottedTextArea(char: string) {
    this.char = char;
  }

  @Output() stringArrayChange = new EventEmitter();

  @HostListener('keydown.enter', ['$event']) onEnter(event: KeyboardEvent) {
    this.rawValue = this.rawValue += `\n${this.char} `;
    event.preventDefault();
  }

  @HostListener('change', ['$event']) change() {
    this.stringArrayChange.emit(this.rawValue.split(`\n${this.char} `));
  }

  constructor(private elementRef: ElementRef) {
  }

  ngOnInit(): void {
    this.rawValue = this.rawValue += `${this.char} `;
  }

  get rawValue(): string {
    return this.elementRef.nativeElement.value;
  }

  set rawValue(value: string) {
    this.elementRef.nativeElement.value = value;
  }
}
