import {Action, createFeatureSelector, createReducer, createSelector, on} from '@ngrx/store';
import * as enumActions from '../enums/enum.actions';

export interface EnumState {
  documentTypes: any[];
  specialRoles: any[];
  userRoles: any[];
  orderTypes: any[];
  brakeTypes: any[];
  trainTypes: any[];
  installationImportanceTypes: any[];
  installationTypes: any[];
  reportTypes: any[];
  messageStatus: any[];
  messageTypes: any[];
}

const initialState: EnumState = {
  documentTypes: [],
  specialRoles: [],
  userRoles: [],
  orderTypes: [],
  brakeTypes: [],
  trainTypes: [],
  installationImportanceTypes: [],
  installationTypes: [],
  reportTypes: [],
  messageStatus: [],
  messageTypes: []
};

const _enumReducer = createReducer(
  initialState,
  on(enumActions.fetchEnumsSuccess,
    (state, {enums}) => ({
      ...state,
      documentTypes: enums.documentTypes,
      specialRoles: enums.specialRoles,
      userRoles: enums.userRoles,
      orderTypes: enums.orderTypes,
      brakeTypes: enums.brakeTypes,
      trainTypes: enums.trainTypes,
      installationImportanceTypes: enums.installationImportanceTypes,
      installationTypes: enums.installationTypes,
      reportTypes: enums.reportTypes,
      messageStatus: enums.messageStatus,
      messageTypes: enums.messageTypes
    }))
);

export function enumReducer(state: EnumState, action: Action) {
  return _enumReducer(state, action);
}

const selectEnumState = createFeatureSelector<EnumState>('enums');

export const selectEnums = createSelector(selectEnumState, (state: EnumState) => state);
export const selectSpecialRoles = createSelector(selectEnumState, (state: EnumState) => state.specialRoles);
export const selectUserRoles = createSelector(selectEnumState, (state: EnumState) => state.userRoles);
export const selectDocumentTypes = createSelector(selectEnumState, (state: EnumState) => state.documentTypes);
export const selectOrderTypes = createSelector(selectEnumState, (state: EnumState) => state.orderTypes);
export const selectBrakeTypes = createSelector(selectEnumState, (state: EnumState) => state.brakeTypes);
export const selectTrainTypes = createSelector(selectEnumState, (state: EnumState) => state.trainTypes);
export const selectInstallationImportanceTypes = createSelector(selectEnumState, (state: EnumState) => state.installationImportanceTypes);
export const selectInstallationTypes = createSelector(selectEnumState, (state: EnumState) => state.installationTypes);
export const selectReportTypes = createSelector(selectEnumState, (state: EnumState) => state.reportTypes);
export const selectMessageStatus = createSelector(selectEnumState, (state: EnumState) => state.messageStatus);
export const selectMessageTypes = createSelector(selectEnumState, (state: EnumState) => state.messageTypes);
