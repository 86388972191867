import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import * as warningActions from './warnings.actions';
import {WarningService} from '../../submodules/logistics/services/warning.service';
import {catchError, map, switchMap} from 'rxjs/operators';
import {of} from 'rxjs';
import {WarningsWrapper} from '../../model/warnings-wrapper';
import {WarningType} from 'src/app/model/warning';

@Injectable()
export class WarningsEffects {

  fetchWarnings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(warningActions.fetchWarnings),
      switchMap(({warningType}) => this.warningService.getWarnings(warningType).pipe(
        map((warnings: WarningsWrapper) => warningActions.fetchWarningsSuccess({
          warnings: warnings.warnings,
          warningType: warningType,
          totalElements: warnings.totalElements
        })),
        catchError(() => of(warningActions.fetchWarningsError()))
      ))
    )
  );

  fetchWarningsWithFiltering$ = createEffect(() =>
    this.actions$.pipe(
      ofType(warningActions.fetchWarningsWithFiltering),
      switchMap(({filter, warningType}) => {
        return this.warningService.getWarnings(warningType, filter).pipe(
          map((warnings: WarningsWrapper) => warningActions.fetchWarningsSuccess({
            warnings: warnings.warnings,
            warningType: warningType,
            totalElements: warnings.totalElements
          })),
          catchError(() => of(warningActions.fetchWarningsError()))
        );
      })
    )
  );

  fetchAllWarnings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(warningActions.fetchAllWarnings),
      switchMap(() => this.warningService.getWarnings(WarningType.permanent).pipe(
        switchMap((permanentWarnings: WarningsWrapper) => this.warningService.getWarnings(WarningType.time).pipe(
          map((timeWarnings: WarningsWrapper) => [permanentWarnings, timeWarnings])
        ))
      )),
      map(([permanentWarnings, timeWarnings]) => warningActions.fetchAllWarningsSuccess({
        permanentWarnings: permanentWarnings.warnings,
        timeWarnings: timeWarnings.warnings,
      })),
      catchError(() => of(warningActions.fetchAllWarningsError()))
    )
  );

  constructor(private actions$: Actions,
              private warningService: WarningService) {
  }

}
