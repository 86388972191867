import {createEntityAdapter, EntityState} from '@ngrx/entity';
import {compareUpdates, Update} from 'src/app/model/update';
import {createReducer, on} from '@ngrx/store';
import * as updateActions from './updates.actions';

export interface UpdatesState extends EntityState<Update> {
  allUpdatesLoaded: boolean;
}

export const adapter = createEntityAdapter<Update>({
  sortComparer: compareUpdates,
});


export const initialUpdateState = adapter.getInitialState({
  allUpdatesLoaded: false
});

export const updateReducer = createReducer(
  initialUpdateState,

  on(updateActions.fetchUpdatesSuccess,
    (state, action) => adapter.addMany(action.updates,
      {
        ...state,
        allUpdatesLoaded: true
      })),

  on(updateActions.addUpdateSuccess,
    (state, action) => adapter.addOne(action.update,
      {...state})),

  on(updateActions.deleteUpdateSuccess,
    (state, action) => adapter.removeOne(action.index,
      {...state}))
);

export const {
  selectAll
} = adapter.getSelectors();
