import {createReducer, on} from '@ngrx/store';
import * as vehicleActions from '../vehicles/vehicles.actions';
import {createEntityAdapter, EntityState} from '@ngrx/entity';
import {Vehicle} from '../../model/vehicle';

export interface VehicleState extends EntityState<Vehicle> {
  allVehiclesLoaded: boolean;
}

export const adapter = createEntityAdapter<Vehicle>({});

export const initialVehicleState = adapter.getInitialState({
  allVehiclesLoaded: false,
});

export const vehicleReducer = createReducer(
  initialVehicleState,
  on(vehicleActions.fetchVehiclesSuccess,
    (state, action) => adapter.setAll(action.vehicles,
      {
        ...state,
        allVehiclesLoaded: true
      }))
);
