import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Report} from '../../../model/report';
import {DownloadService} from './download.service';
import {saveAs} from 'file-saver';
import {ReportSchedule} from '../../../model/report-schedule';
import {ReportFilter, ReportFilterParams} from '../../../model/report-filter';
import {ReportWrapper} from '../../../model/report-wrapper';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class ReportService {
  reportPath = '/api/suc/reports';
  reportSchedulePath = '/api/suc/reports/schedule';

  constructor(private http: HttpClient,
              private downloadService: DownloadService) {
  }

  getReports(reportFilter?: ReportFilter): Observable<ReportWrapper> {
    const reportFilterParams = new ReportFilterParams(reportFilter);
    return this.http.get<ReportWrapper>(this.reportPath + reportFilterParams.createFilterRequestParams());
  }

  getReport(id: number): Observable<Report> {
    return this.http.get<Report>(`${this.reportPath}/${id}`);
  }

  downloadReport(report: Report) {
    this.downloadService.getReport(report.id).subscribe((response: any) => {
      const blob: any = new Blob([response], {type: 'text/json; charset=utf-8'});
      saveAs(blob, report.name + '.' + report.extension.toLowerCase());
    });
  }

  scheduleReport(report: ReportSchedule, startDate: Date, endDate: Date): Observable<ReportSchedule> {
    const params = new HttpParams()
      .set('startDate', moment(startDate).format('YYYY-MM-DD'))
      .set('endDate', moment(endDate).format('YYYY-MM-DD'));
    return this.http.post<ReportSchedule>(`${this.reportSchedulePath}`, report, {params});
  }

  saveReport(report: Report, startDate: Date, endDate: Date): Observable<ReportWrapper> {
    const body = {name: report.name, creationDate: report.creationDate, reportType: report.reportType, creatorId: report.creatorId};
    const params = new HttpParams()
      .set('startDate', moment(startDate).format('YYYY-MM-DD'))
      .set('endDate', moment(endDate).format('YYYY-MM-DD'));
    return this.http.post<ReportWrapper>(`${this.reportPath}`, body, {params});
  }

  sendEmail(id: number, email: string[]) {
    const body = {id: id, email: email};
    return this.http.post(`${this.reportPath}/email`, body);
  }

  editReport(report: Report): Observable<Report> {
    return this.http.put<Report>(`${this.reportPath}`, report);
  }

  deleteReport(id: number): Observable<any> {
    return this.http.delete(`${this.reportPath}/${id}`);
  }
}
