import {ElementRef, Injectable} from '@angular/core';

export type TooltipModalCallback = (element: ElementRef<HTMLElement>, name: string, group: string) => void;

@Injectable({
  providedIn: 'root'
})
export class TooltipService {

  private bodies: { [key: string]: { [key: string]: { order: number, element: ElementRef<HTMLElement> } } } = {};
  private toolTippedElements: { [key: string]: { [key: string]: ElementRef<HTMLElement>[] } } = {};

  private overlays: { [key: string]: { [key: string]: HTMLDivElement[] } } = {};

  private _modalCallback: TooltipModalCallback;

  set modalCallback(c: TooltipModalCallback) {
    this._modalCallback = c;
  }

  constructor() {
  }

  registerBody(body: ElementRef<HTMLHtmlElement>, name: string, group: string, order?: number) {
    if (this.bodies[group] === undefined || this.bodies[group] === null) {
      this.bodies[group] = {};
    }

    const gr = this.bodies[group];
    if (order === undefined || order === null) {
      const max = Object.values(gr).map(v => v.order).reduce((a, b) => a >= b ? a : b, 0);
      order = max + 1;
    }

    gr[name] = {order: order, element: body};
  }

  unregisterBody(name: string, group: string) {
    if (this.bodies[group] === undefined || this.bodies[group] === null) {
      return;
    }
    const gr = this.bodies[group];
    delete gr[name];
  }


  registerTooltippedElement(element: ElementRef<HTMLHtmlElement>, name: string, group: string) {
    if (this.toolTippedElements[group] === undefined || this.toolTippedElements[group] === null) {
      this.toolTippedElements[group] = {};
    }

    const gr = this.toolTippedElements[group];

    if (gr[name] === undefined || gr[name] === null) {
      gr[name] = [];
    }

    gr[name].push(element);
  }

  unregisterTooltippedElement(name: string, group: string) {
    if (this.toolTippedElements[group] === undefined || this.toolTippedElements[group] === null) {
      return;
    }
    const gr = this.toolTippedElements[group];
    delete gr[name];
  }

  highlight(name: string, group: string) {
    this.unhighlight(name, group);

    if (!this.toolTippedElements[group]) {
      return;
    }

    const gr = this.toolTippedElements[group];

    if (!gr[name]) {
      return;
    }

    if (!this.overlays[group]) {
      this.overlays[group] = {};
    }

    const overlayGr = this.overlays[group];
    overlayGr[name] = [];

    gr[name].map(e => e as ElementRef<HTMLHtmlElement>).forEach(e => {
      const rect = e.nativeElement.getBoundingClientRect();
      const div = document.createElement('div');
      div.classList.add('tooltip-overlay');
      div.style.top = rect.top + 'px';
      div.style.left = rect.left + 'px';
      div.style.width = rect.width + 'px';
      div.style.height = rect.height + 'px';

      overlayGr[name].push(div);
      document.body.appendChild(div);
    });
  }

  unhighlight(name: string, group: string) {
    if (!this.toolTippedElements[group]) {
      return;
    }

    const gr = this.toolTippedElements[group];

    if (!gr[name]) {
      return;
    }

    if (!this.overlays[group]) {
      return;
    }

    const overlayGr = this.overlays[group];

    if (overlayGr[name]) {
      overlayGr[name].forEach(e => e.remove());
    }

    overlayGr[name] = null;
  }

  showTooltip(name: string, group: string) {
    if (!this._modalCallback) {
      return;
    }

    if (!this.bodies[group]) {
      return;
    }

    const gr = this.bodies[group];

    if (!gr[name]) {
      return;
    }

    this.highlight(name, group);
    this._modalCallback(gr[name].element, name, group);
  }
}
