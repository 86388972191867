import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Infrastructure} from '../../../model/infrastructure';

@Injectable({
  providedIn: 'root'
})
export class InfrastructureService {
  infrastructurePath = '/api/suc/cbd/osm/infrastructure';

  constructor(private http: HttpClient) {
  }

  getInfrastructureElements(): Observable<Infrastructure[]> {
    return this.http.get<Infrastructure[]>(`${this.infrastructurePath}`);
  }
}
