import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-spinner-button',
  templateUrl: './spinner-button.component.html',
  styleUrls: ['./spinner-button.component.less']
})
export class SpinnerButtonComponent implements OnInit {

  @Input() spinnerEnabled = false;
  @Input() disabled = false;
  @Input() disableWhenSpinnerIsActive = true;
  @Input() buttonClass = '';
  @Input() buttonType: 'submit' | 'button' | 'reset' = 'button';
  @Input() text = '';
  @Input() icon: string = null;
  @Input() greyButton = false;
  @Input() active = false;

  @Output() buttonClick = new EventEmitter();

  mainButtonStyle = 'menu-button';

  constructor() {
  }

  ngOnInit() {
    if (this.greyButton) {
      this.mainButtonStyle = 'btn-secondary';
    }
  }

}
