import * as moment from 'moment';

export interface MessageFilter {
  status: string;
  type: string;
  title: string;
  creationDate: Date;
}

export class MessageFilterParams {
  status: string;
  type: string;
  title: string;
  creationDate: Date;

  constructor(filter?: MessageFilter) {
    if (!!filter) {
      for (const prop of Object.keys(filter)) {
        this[prop] = filter[prop];
      }
    }
  }

  public createFilterRequestParams(): string {
    const tableState = sessionStorage.getItem('messages-table-state');
    const {
      first = 0,
      rows = 25
    } = tableState ? JSON.parse(tableState) : {};
    const properties = [
      {field: 'status', formatter: (value) => value},
      {field: 'type', formatter: (value) => value},
      {field: 'title', formatter: (value) => value},
      {field: 'creationDate', formatter: (value) => moment(value).format('YYYY-MM-DD')},
    ];

    let queryString = '?';
    for (let property of properties) {
      const value = this[property.field];
      if (value) {
        queryString += `${property.field}=${property.formatter(value)}&`;
      }
    }
    queryString += `offset=${first}&`;
    queryString += `limit=${rows}`;
    return queryString;

  }
}
