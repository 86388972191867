import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {AuthService} from '../submodules/commons/services/auth.service';
import {HeartbeatService} from '../submodules/commons/services/heartbeat.service';
import {Store} from '@ngrx/store';
import {AppState} from '../store/app.reducer';
import {fetchEnums} from '../store/enums/enum.actions';
import {fetchCarrierList} from '../store/carriers/carrier.actions';
import {PrimeNGConfig} from 'primeng/api';
import {calendarLocale} from '../model/calendar-locale';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.less']
})
export class AppComponent implements OnInit {

  heartbeatInterval: any;

  public constructor(private router: Router,
                     private authService: AuthService,
                     private heartbeatService: HeartbeatService,
                     private store: Store<AppState>,
                     private config: PrimeNGConfig) {
  }

  ngOnInit(): void {
    this.sendHeartbeat(true);

    this.heartbeatInterval = setInterval(() => this.sendHeartbeat(), 60 * 1000);

    window.addEventListener('focus', () => {
      this.sendHeartbeat(true);
    });

    if (this.authService.checkIfContainsRoles(['ADMIN', 'OPERATOR'])) {
      this.store.dispatch(fetchEnums());
      this.store.dispatch(fetchCarrierList());
    }
    this.config.setTranslation(calendarLocale);
  }

  private sendHeartbeat(force: boolean = false) {
    if (force || (!document.hidden && document.hasFocus())) {
      setTimeout(() => this.heartbeatService.ping().subscribe());
    }
  }

  public hasCustomNavbar(): boolean {
    const url = this.router.url;
    const urlWithoutParams = url.indexOf('?') >= 0 ? url.substring(0, url.indexOf('?')) : url;
    return urlWithoutParams === '/' || urlWithoutParams === '/run';
  }
}
