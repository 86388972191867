import {Component, Input, OnInit} from '@angular/core';
import {ValidationHelperService} from '../../services/validation-helper.service';
import {ContactUsService} from '../../services/contact-us.service';
import {initMaterialDesign} from '../../../../components/utils/utils';

@Component({
  selector: 'app-contact-form',
  templateUrl: './contact-form.component.html',
  styleUrls: ['./contact-form.component.less']
})
export class ContactFormComponent implements OnInit {

  name: string;
  email: string;
  message: string;
  messageSent = false;

  @Input() showNameInput = true;
  @Input() showEmailInput = true;

  constructor(public validationService: ValidationHelperService,
              private contactUsService: ContactUsService) {
  }

  ngOnInit() {
    initMaterialDesign('app-contact-form');
  }

  submit(ngForm) {
    this.validationService.ifValidThenSubscribe(ngForm,
      () => this.contactUsService.sendMessage(this.name, this.email, this.message, 'contact-us'),
      () => this.success(ngForm));
  }

  private success(ngForm: any) {
    this.validationService.resetForm(ngForm);
    this.messageSent = true;
    initMaterialDesign('app-contact-form');
  }
}
