import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import * as vehicleActions from '../vehicles/vehicles.actions';
import {catchError, map, switchMap} from 'rxjs/operators';
import {of} from 'rxjs';
import {VehicleService} from '../../submodules/logistics/services/vehicle.service';
import {Vehicle} from '../../model/vehicle';

@Injectable()
export class VehiclesEffects {
  fetchVehicles$ = createEffect(() =>
    this.actions$.pipe(
      ofType(vehicleActions.fetchActiveVehicles),
      switchMap(() => this.vehicleService.getActiveVehicles().pipe(
        map((vehicles: Vehicle[]) => vehicleActions.fetchVehiclesSuccess({vehicles: vehicles})),
        catchError(() => of(vehicleActions.fetchVehiclesError()))
      ))
    )
  );

  constructor(private actions$: Actions,
              private vehicleService: VehicleService) {
  }
}
