import {createReducer, on} from '@ngrx/store';
import * as messageActions from '../messages/messages.actions';
import {compareMessages, Message} from '../../model/message';
import {createEntityAdapter, EntityState} from '@ngrx/entity';

export interface MessagesState extends EntityState<Message> {
  allMessagesLoaded: boolean;
  totalElements: number;
}

export const adapter = createEntityAdapter<Message>({
  sortComparer: compareMessages
});

export const initialMessageState = adapter.getInitialState({
  allMessagesLoaded: false,
  totalElements: 0
});

export const messageReducer = createReducer(
  initialMessageState,
  on(messageActions.fetchMessagesSuccess,
    (state, action) => adapter.setAll(action.messages,
      {
        ...state,
        totalElements: action.totalElements,
        allMessagesLoaded: true
      })),
  on(messageActions.addMessagesSuccess,
    (state, action) => adapter.addOne(action.message,
      {...state})),
  on(messageActions.deleteMessagesSuccess,
    (state, action) => adapter.removeOne(action.index,
      {...state}))
);
