import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import * as trainActions from './/trains.actions';
import {catchError, map, switchMap} from 'rxjs/operators';
import {of} from 'rxjs';
import {TrainService} from '../../submodules/logistics/services/train.service';
import {Train} from '../../model/train';

@Injectable()
export class TrainsEffects {
  fetchTrains$ = createEffect(() =>
    this.actions$.pipe(
      ofType(trainActions.fetchTrains),
      switchMap(() => this.trainService.getTrains().pipe(
        map((trains: Train[]) => trainActions.fetchTrainsSuccess({trains: trains})),
        catchError(() => of(trainActions.fetchTrainsError()))
      ))
    )
  );

  constructor(private actions$: Actions,
              private trainService: TrainService) {
  }
}
