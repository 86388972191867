import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Message} from '../../../model/message';
import {Injectable} from '@angular/core';
import {MessageEvent} from '../../../model/message-event';
import {MessageFilter, MessageFilterParams} from '../../../model/message-filter';
import {MessageWrapper} from '../../../model/message-wrapper';
import {tap} from 'rxjs/operators';
import * as messageActions from '../../../store/messages/messages.actions';
import {MapEvents} from '../../../model/map-events';
import {MapService} from './map.service';
import {Store} from '@ngrx/store';
import {AppState} from '../../../store/app.reducer';

@Injectable({
  providedIn: 'root'
})
export class MessagesService {
  messagesPath = '/api/suc/zkm/messages';

  constructor(private http: HttpClient,
              private mapService: MapService,
              private store: Store<AppState>) {
  }

  getMessages(messageFilter?: MessageFilter): Observable<MessageWrapper> {
    const messageFilterParams = new MessageFilterParams(messageFilter);
    return this.http.get<MessageWrapper>(this.messagesPath + messageFilterParams.createFilterRequestParams());
  }

  saveMessage(message: MessageEvent): Observable<Message> {
    return this.http.post<Message>(`${this.messagesPath}/event`, message);
  }

  editMessage(message: Message): Observable<Message> {
    return this.http.put<Message>(`${this.messagesPath}`, message);
  }

  deleteMessage(id: number): Observable<any> {
    return this.http.delete(`${this.messagesPath}/${id}`);
  }

  createMessageCommon(message: MessageEvent): Observable<Message> {
    return this.saveMessage(message).pipe(
      tap(m => this.store.dispatch(messageActions.addMessagesSuccess({message: m})))
    );
  }

  deleteMessageCommon(id): Observable<Message> {
    return this.deleteMessage(id).pipe(
      tap(() => this.store.dispatch(messageActions.deleteMessagesSuccess({index: id})))
    );
  }
}
