import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Travel} from '../../../model/travel';
import {Result} from '../../../model/result';

@Injectable({
  providedIn: 'root'
})
export class TravelService {

  travelPath = '/api/suc/cbd/travel';
  resultPath = '/api/suc/cbd/travel/result';

  constructor(private httpClient: HttpClient) {
  }

  public getTravels(): Observable<Travel[]> {
    return this.httpClient.get<Travel[]>(this.travelPath);
  }

  public getResults(): Observable<Result[]> {
    return this.httpClient.get<Result[]>(this.resultPath);
  }
}
