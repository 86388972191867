import {Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';

declare const $: any;

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.less']
})
export class DialogComponent implements OnInit, OnDestroy {
  @Input() title;
  @Input() button1Label: string;
  @Input() button2Label: string;
  @Input() button1Class = 'btn-default';
  @Input() button2Class = 'btn-info btn-raised';
  @Input() icon = 'fa-exclamation-triangle';
  @Input() closeable = false;
  @Input() backdrop: (true | false | 'static') = 'static';
  @Input() centered = false;
  @Input() button2SpinnerEnabled = false;
  @Input() width = '500px';
  @Input() height = '265px';
  @Input() disableButton2 = false;
  @Input() disableButton1 = false;


  @Output() button1Callback: EventEmitter<any> = new EventEmitter();
  @Output() button2Callback: EventEmitter<any> = new EventEmitter();
  @Output() closeCallback: EventEmitter<any> = new EventEmitter();

  private _visible = false;
  public get visible() {
    return this._visible;
  }


  constructor(private elementRef: ElementRef) {
  }

  ngOnInit() {
    $(this.elementRef.nativeElement).detach().appendTo('body');

    $(this.getDialog()).on('show.bs.modal', () => this._visible = true);
    $(this.getDialog()).on('hidden.bs.modal', () => this._visible = false);
  }

  button1Click() {
    this.button1Callback.emit();
  }

  button2Click() {
    this.button2Callback.emit();
  }

  closeClick() {
    this.closeCallback.emit();
  }

  show() {
    $(this.getDialog()).modal('show');
  }

  hide() {
    $(this.getDialog()).modal('hide');
  }

  private getDialog() {
    return this.elementRef.nativeElement.querySelector('[role=dialog]');
  }

  ngOnDestroy(): void {
    if (this._visible) {
      $(this.getDialog()).on('hidden.bs.modal', () => this.removeFromDOM());
    } else {
      this.removeFromDOM();
    }
  }

  private removeFromDOM() {
    $(this.elementRef.nativeElement).remove();
  }
}
