import {NgModule} from '@angular/core';
import {PreloadAllModules, RouterModule, Routes} from '@angular/router';
import {LandingComponent} from './components/landing/landing.component';
import {EmptyComponent} from './components/empty/empty.component';
import {CookiePolicyComponent} from './components/cookie-policy/cookie-policy.component';
import {AuthenticatedGuard} from './guards/authenticated-guard.service';

const routes: Routes = [
  {path: '', component: LandingComponent, canActivate: [AuthenticatedGuard]},
  {path: 'reload', component: EmptyComponent},
  {path: 'cookie-policy', component: CookiePolicyComponent},
  {
    path: '',
    loadChildren: 'src/app/submodules/logistics/logistics.module#LogisticsModule'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {preloadingStrategy: PreloadAllModules})],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
