import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import 'rxjs/add/operator/do';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/switchMap';
import 'rxjs/add/observable/throw';
import 'rxjs/add/operator/finally';
import 'rxjs/add/operator/filter';
import 'rxjs/add/operator/take';
import {Router} from '@angular/router';

@Injectable()
export class SessionExpiredInterceptor implements HttpInterceptor {

  whitelist = [
    '/api/login',
    '/api/me',
  ];

  constructor(private router: Router) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.whitelist.includes(request.url)) {
      return next.handle(request);
    }

    return next.handle(request).catch((error: any) => {
      if (error instanceof HttpErrorResponse && error.status === 401) {
        console.log('Session expired');
        this.router.navigate(['/'], {queryParams: {sessionExpired: true}});
      }

      return Observable.throwError(error);
    });
  }

}
