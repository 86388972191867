import {MessageEventType} from './message-event-type';
import {MessageStatus} from './message-status';

export interface Message {
  id: number;
  status: string;
  type: string;
  eventType: MessageEventType;
  title: string;
  content: string;
  creationDate: Date;
  lat: number;
  lng: number;
  confirmationNo: number;
  creatorId: string;
  carriers: number[];
}

export function compareMessages(message1: Message, message2: Message) {
  const compare = new Date(message1.creationDate).getTime() - new Date(message2.creationDate).getTime();
  return compare < 0 ? 1 : compare > 0 ? -1 : 0;
}

export const messageStatuses = [
  {label: 'Nowe', value: 'NEW'},
  {label: 'Potwierdzone', value: 'CONFIRMED'},
  {label: 'Niezatwierdzony', value: 'WARNING'},
];

export const messageStatusIcons = {
  [MessageStatus.NEW]: './assets/icons/messages/new.png',
  [MessageStatus.CONFIRMED]: './assets/icons/messages/confirmed.png',
  [MessageStatus.WARNING]: './assets/icons/messages/warning.png'
};

export const messageTypesIcons = {
  ['Czasowy']: './assets/icons/messages/time.png',
  ['Stały']: './assets/icons/messages/permanent.png',
  ['Krytyczny']: './assets/icons/messages/critical.png',
  ['null']: './assets/icons/messages/choose.png'
};
