import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import * as enumActions from '../enums/enum.actions';
import {catchError, map, switchMap} from 'rxjs/operators';
import {of} from 'rxjs';
import {EnumService} from '../../submodules/commons/services/enum.service';

@Injectable()
export class EnumEffects {

  fetchEnums$ = createEffect(() =>
    this.actions$.pipe(
      ofType(enumActions.fetchEnums),
      switchMap(() => {
        return this.enumService.getEnums().pipe(
          map((enums) => enumActions.fetchEnumsSuccess({enums})),
          catchError(() => of(enumActions.fetchEnumsError()))
        );
      })
    )
  );

  constructor(
    private actions$: Actions,
    private enumService: EnumService
  ) {
  }

}
