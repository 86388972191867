import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'propertyFilter'
})
export class PropertyFilterPipe implements PipeTransform {

  transform(value: any[], filterSpec: { fieldName: string; fieldValue: any; relation: ('===' | '!==' | '==' | '!=' | '>' | '<' | '>=' | '<=') }[]): any {
    if (!filterSpec || filterSpec.length === 0) {
      return value;
    }

    return value.filter((item: any): boolean => {
      let result = true;
      for (let i = 0; i < filterSpec.length; i++) {
        const f = filterSpec[i];
        if (!(f.fieldName in item)) {
          continue;
        }

        const v = item[f.fieldName];

        let r = true;
        switch (f.relation) {
          case '===':
            r = v === f.fieldValue;
            break;
          case '!==':
            r = v !== f.fieldValue;
            break;
          case '==':
            r = v === f.fieldValue;
            break;
          case '!=':
            r = v !== f.fieldValue;
            break;
          case '>':
            r = v > f.fieldValue;
            break;
          case '<':
            r = v < f.fieldValue;
            break;
          case '>=':
            r = v >= f.fieldValue;
            break;
          case '<=':
            r = v <= f.fieldValue;
            break;
        }
        result = result && r;
      }
      return result;
    });
  }

}
