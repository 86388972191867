import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import * as messageActions from '../messages/messages.actions';
import {catchError, map, switchMap} from 'rxjs/operators';
import {of} from 'rxjs';
import {MessagesService} from '../../submodules/logistics/services/message.service';
import {MessageWrapper} from '../../model/message-wrapper';

@Injectable()
export class MessagesEffects {
  fetchMessages$ = createEffect(() =>
    this.actions$.pipe(
      ofType(messageActions.fetchMessages),
      switchMap(() => this.messageService.getMessages().pipe(
        map((wrapper: MessageWrapper) => messageActions.fetchMessagesSuccess({
          messages: wrapper.messages,
          totalElements: wrapper.totalElements
        })),
        catchError(() => of(messageActions.fetchMessagesError()))
      ))
    )
  );

  fetchMessagesWithFiltering$ = createEffect(() =>
    this.actions$.pipe(
      ofType(messageActions.fetchMessagesWithFiltering),
      switchMap(({filter}) => {
        return this.messageService.getMessages(filter).pipe(
          map((wrapper: MessageWrapper) => messageActions.fetchMessagesSuccess({
            messages: wrapper.messages,
            totalElements: wrapper.totalElements
          })),
          catchError(() => of(messageActions.fetchMessagesError()))
        );
      })
    )
  );

  constructor(private actions$: Actions,
              private messageService: MessagesService) {
  }
}
