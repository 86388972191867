import {Action, createFeatureSelector, createReducer, createSelector, on} from '@ngrx/store';
import * as trainActions from '../trains/trains.actions';
import {Train} from '../../model/train';

export interface TrainState {
  trains: Train[];
}

const initialState: TrainState = {
  trains: [],
};

const _trainsReducer = createReducer(
  initialState,
  on(trainActions.fetchTrainsSuccess,
    (state, {trains}) => ({
      ...state,
      trains: trains
    })),
  on(trainActions.addTrainsSuccess,
    (state, {train}) => ({
      ...state,
      trains: [...state.trains, train]
    })),
  on(trainActions.updateTrainsSuccess,
    (state, {train}) => ({
      ...state,
      trains: state.trains.map(v => v.uniqueNumber === train.uniqueNumber ? train : v)
    })),
  on(trainActions.deleteTrainSuccess,
    (state, {index}) => ({
      ...state,
      trains: state.trains.filter(v => v.uniqueNumber !== index)
    })),
);

export function trainReducer(state: TrainState, action: Action) {
  return _trainsReducer(state, action);
}

const selectTrainState = createFeatureSelector<TrainState>('trains');

export const selectTrains = createSelector(selectTrainState, (state: TrainState) => state.trains);

export const selectTrainsWithFiltering = (createdDate: Date) => createSelector(selectTrainState,
  (state: TrainState) => state.trains.filter(train => new Date(train.createdDate).getDate() >= createdDate.getDate()));
