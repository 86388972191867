import {AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {TooltipService} from '../../services/tooltip.service';

@Component({
  selector: 'app-tooltip',
  templateUrl: './tooltip.component.html',
  styleUrls: ['./tooltip.component.less']
})
export class TooltipComponent implements OnInit, AfterViewInit, OnDestroy {

  @Input() name: string;
  @Input() group = 'default';
  @Input() order: number;
  @ViewChild('contentWrapper') contentWrapper: ElementRef<HTMLHtmlElement>;

  constructor(private mainElement: ElementRef<HTMLHtmlElement>, private tooltipService: TooltipService) {
  }

  ngOnInit() {
  }

  ngAfterViewInit(): void {
    this.contentWrapper.nativeElement.remove();
    this.contentWrapper.nativeElement.style.display = null;
    this.tooltipService.registerBody(this.contentWrapper, this.name, this.group, this.order);
  }

  toolTipClick() {
    this.tooltipService.showTooltip(this.name, this.group);
  }

  ngOnDestroy(): void {
    this.tooltipService.unregisterBody(this.name, this.group);
    this.tooltipService.unhighlight(this.name, this.group);
    this.contentWrapper.nativeElement.remove();
    this.contentWrapper.nativeElement.style.display = 'none';
    this.mainElement.nativeElement.appendChild(this.contentWrapper.nativeElement);
  }

}
