import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {DialogComponent} from '../dialog/dialog.component';
import {TooltipService} from '../../services/tooltip.service';

@Component({
  selector: 'app-tooltip-modal',
  templateUrl: './tooltip-modal.component.html',
  styleUrls: ['./tooltip-modal.component.less']
})
export class TooltipModalComponent implements OnInit {

  @ViewChild('dialog') dialog: DialogComponent;
  @ViewChild('container') container: ElementRef<HTMLElement>;

  private displayedElement: ElementRef<HTMLElement>;
  private displayedName: string;
  private displayedGroup: string;

  constructor(private tooltipService: TooltipService) {
    tooltipService.modalCallback = (element, name, group) => {
      if (this.displayedElement) {
        this.displayedElement.nativeElement.remove();
      }
      this.displayedElement = element;
      this.container.nativeElement.appendChild(element.nativeElement);
      this.displayedName = name;
      this.displayedGroup = group;
      this.dialog.show();
    };
  }

  ngOnInit() {
  }

  okClicked() {
    this.dialog.hide();
    this.tooltipService.unhighlight(this.displayedName, this.displayedGroup);
  }
}
