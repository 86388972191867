import * as moment from "moment";

export interface WarningFilter {
  lineNumber: number;
  locationName: string;
  trackNumber: number;
  createdDate: Date;
  endDate: Date;
}

export class WarningFilterParams {
  lineNumber = null;
  locationName = '';
  trackNumber = null;
  createdDate = null;
  endDate = null;

  constructor(filter?: WarningFilter) {
    if (!!filter) {
      for (const prop of Object.keys(filter)) {
        this[prop] = filter[prop];
      }
    }
  }

  public createFilterRequestParams(): string {
    const tableState = sessionStorage.getItem('warnings-table-state');
    const {
      first = 0,
      rows = 25
    } = tableState ? JSON.parse(tableState) : {};
    const properties = [
      {field: "lineNumber", formatter: (value) => value},
      {field: "locationName", formatter: (value) => value},
      {field: "trackNumber", formatter: (value) => value},
      {field: "createdDate", formatter: (value) => moment(value).format('YYYY-MM-DD')},
      {field: "endDate", formatter: (value) => moment(value).format('YYYY-MM-DD')},
    ];
    let queryString = '?';
    for (let property of properties) {
      const value = this[property.field]
      if (value) {
        queryString += `${property.field}=${property.formatter(value)}&`;
      }
    }
    queryString += `offset=${first}&`;
    queryString += `limit=${rows}`;
    return queryString;

  }
}
