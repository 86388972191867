import {Injectable} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {Observable} from 'rxjs';

@Injectable()
export class ValidationHelperService {

  constructor() {
  }

  public touchForm(form: FormGroup) {
    Object.keys(form.controls).map((controlName) => {
      form.get(controlName).markAsTouched({onlySelf: true});
    });
  }

  public markAsDirty(form: FormGroup) {
    Object.keys(form.controls).map((controlName) => {
      form.get(controlName).markAsDirty({onlySelf: true});
    });
  }

  public resetForm(ngForm: FormGroup) {
    ngForm.reset();
  }

  ifValidThen(ngForm: any, action: () => void) {
    this.touchForm(ngForm.form);

    if (ngForm.valid) {
      action();
    }
  }

  ifValidThenReactive(form: FormGroup, action: () => void) {
    this.markAsDirty(form);

    if (form.valid) {
      action();
    }
  }

  ifValidThenSubscribe(ngForm: any, action: () => Observable<Object>, success: () => void, error?: (any) => void) {
    this.touchForm(ngForm.form);

    if (ngForm.valid) {
      action().subscribe(() => success(), error);
    }
  }

  labelInvalid(input) {
    return input.invalid && (input.dirty || input.touched);
  }
}
