import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import * as carrierActions from '../carriers/carrier.actions';
import {catchError, map, switchMap} from 'rxjs/operators';
import {of} from 'rxjs';
import {Carrier} from "../../model/carrier";
import {CarrierService} from "../../submodules/logistics/services/carrier.service";

@Injectable()
export class CarrierEffects {

  fetchCarrierList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(carrierActions.fetchCarrierList),
      switchMap(() => {
        return this.carrierService.getCarriers().pipe(
          map((carrierList: Carrier[]) => carrierActions.fetchCarrierListSuccess({carrierList: carrierList})),
          catchError(() => of(carrierActions.fetchCarrierListError()))
        );
      })
    )
  )


  constructor(
    private actions$: Actions,
    private carrierService: CarrierService
  ) {
  }
}
