import {createEntityAdapter, EntityState} from '@ngrx/entity';
import {createReducer, on} from '@ngrx/store';
import * as infrastructureActions from '../infrastructure/infrastructure.actions';
import {Infrastructure} from '../../model/infrastructure';

export interface InfrastructureState extends EntityState<Infrastructure> {
  allInfrastructuresLoaded: boolean;
}

export const adapter = createEntityAdapter<Infrastructure>({
  selectId: (infrastructure: Infrastructure) => infrastructure.osmId,
  sortComparer: null
});


export const initialInfrastructureState = adapter.getInitialState({
  allInfrastructuresLoaded: false
});

export const infrastructureReducer = createReducer(
  initialInfrastructureState,

  on(infrastructureActions.fetchInfrastructuresSuccess,
    (state, action) => adapter.setAll(action.infrastructures,
      {
        ...state,
        allInfrastructuresLoaded: true
      }))
);

export const {
  selectAll
} = adapter.getSelectors();
