export interface Warning {
  id: number;
  lineNumber: number;
  locationName: string;
  trackNumber: number;
  creationDate: Date;
  endTime: Date;
  comments: string;
  active: boolean;
  type: WarningType;
  lat: number;
  lng: number;
  radius: number;
  carriers: number[];
}

export enum WarningType {
  time = 'time',
  permanent = 'permanent'
}

export const WarningTypes: string[] = [WarningType.permanent, WarningType.time];
