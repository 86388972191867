import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import 'rxjs/add/operator/do';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/switchMap';
import 'rxjs/add/observable/throw';
import 'rxjs/add/operator/finally';
import 'rxjs/add/operator/filter';
import 'rxjs/add/operator/take';

declare const $: any;

@Injectable()
export class ConnectionRefusedInterceptor implements HttpInterceptor {
  constructor() {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    return next.handle(request).catch((error: any) => {
      if (error instanceof HttpErrorResponse && (error.status === 504 || error.status === 0)) {
        const $modal = $('#connectionRefusedModal');
        if (!$modal.hasClass('show')) {
          $modal.modal('show');
        }
      }

      return Observable.throwError(error);
    });
  }

}
