import {ActionReducerMap} from '@ngrx/store';
import * as fromCarrier from '../store/carriers/carrier.reducer';
import * as fromEnum from '../store/enums/enum.reducer';
import * as fromWarning from '../store/warnings/warnigns.reducer';
import * as fromTrain from './trains/train.reducer';
import * as fromReport from '../store/reports/reports.reducer';
import * as fromMessage from '../store/messages/messages.reducer';
import * as fromVehicle from '../store/vehicles/vehicles.reducer';
import * as fromUpdate from '../store/updates/updates.reducer';
import * as fromTravel from '../store/travels/travels.reducer';
import * as fromResult from '../store/results/results.reducer';
import * as fromInfrastructure from '../store/infrastructure/infrastructure.reducer';
import * as fromAvatar from '../store/avatar/avatar.reducer';

export interface AppState {
  avatar: fromAvatar.AvatarState;
  enums: fromEnum.EnumState;
  carrierList: fromCarrier.CarrierState;
  warnings: fromWarning.WarningState;
  trains: fromTrain.TrainState;
  messages: fromMessage.MessagesState;
  vehicles: fromVehicle.VehicleState;
  reports: fromReport.ReportsState;
  updates: fromUpdate.UpdatesState;
  travels: fromTravel.TravelState;
  results: fromResult.ResultState;
  infrastructures: fromInfrastructure.InfrastructureState;
}

export const appReducer: ActionReducerMap<AppState> = {
  avatar: fromAvatar.avatarReducer,
  enums: fromEnum.enumReducer,
  carrierList: fromCarrier.carrierReducer,
  warnings: fromWarning.warningReducer,
  trains: fromTrain.trainReducer,
  reports: fromReport.reportReducer,
  updates: fromUpdate.updateReducer,
  messages: fromMessage.messageReducer,
  vehicles: fromVehicle.vehicleReducer,
  travels: fromTravel.travelReducer,
  results: fromResult.resultReducer,
  infrastructures: fromInfrastructure.infrastructureReducer
};
