import {AfterViewInit, Component, ElementRef, Input, SecurityContext, ViewChild} from '@angular/core';
import {KeycloakUser} from '../../../../model/keycloak-user';
import {isBase64} from '../../utils/image-utils';
import {Store} from '@ngrx/store';
import {AppState} from '../../../../store/app.reducer';
import {AvatarState, getAvatarState} from '../../../../store/avatar/avatar.reducer';
import {SafeUrl} from '@angular/platform-browser';
import {checkIfAvatarExist, checkIfUserAttributeExist, getAvatarUrl} from '../../utils/keycloak-utils';
import {AvatarInfo} from '../../../../model/avatar-info';
import {ɵDomSanitizerImpl} from '@angular/platform-browser';
import {HttpClient} from '@angular/common/http';
import {UserService} from '../../../logistics/services/user.service';

@Component({
  selector: 'app-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.less']
})
export class AvatarComponent implements AfterViewInit {

  @ViewChild('img', {static: false}) img: ElementRef;
  @ViewChild('canvasElement', {static: false}) canvas: ElementRef<HTMLCanvasElement>;

  @Input() user: KeycloakUser;
  @Input() current = false;
  @Input() width = 45;
  @Input() height = 45;
  safeUrl: SafeUrl;

  checkIfUserAttributeExist = checkIfUserAttributeExist;

  isBase64 = isBase64;

  constructor(private store: Store<AppState>,
              private httpClient: HttpClient,
              protected _sanitizerImpl: ɵDomSanitizerImpl,
              private userService: UserService) {
  }

  ngAfterViewInit(): void {
    if (this.current) {
      this.setCurrentUserAvatar();
    } else if (this.avatarExist) {
      this.setUserAvatar();
    }
  }

  setCurrentUserAvatar() {
    this.store.select(getAvatarState).subscribe((state: AvatarState) => {
      this.safeUrl = state.safeAvatarUrl;
      this.displayImage();
    });
  }

  setUserAvatar() {
    this.userService.getSecureImage(this.avatarUrl).subscribe((safe) => {
      this.safeUrl = safe;
      this.displayImage();
    });
  }

  get avatarUrl(): string {
    return getAvatarUrl(this.user);
  }

  get avatarExist(): boolean {
    return checkIfAvatarExist(this.user) && !isBase64(this.user.attributes['avatar_url'][0]);
  }


  displayImage(): void {
    if (!this.canvas || !this.safeUrl) {
      return;
    }

    const context = this.canvas.nativeElement.getContext('2d');
    const img = new Image();
    img.onload = () => this.drawRegularImage(context, img);
    img.src = this._sanitizerImpl.sanitize(SecurityContext.URL, this.safeUrl);
  }

  drawRegularImage(context: CanvasRenderingContext2D, img: CanvasImageSource): void {
    if (!checkIfUserAttributeExist(this.user, 'avatar_info')) {
      context.drawImage(img, 0, 0);
    } else {
      this.drawCroppedImage(context, img);
    }
  }

  drawCroppedImage(context: CanvasRenderingContext2D, img: CanvasImageSource): void {
    const avatarInfo: AvatarInfo = JSON.parse(this.user.attributes['avatar_info'][0]);
    const coordinates = avatarInfo.imagePosition;
    const width = coordinates.x2 - coordinates.x1;
    const height = coordinates.y2 - coordinates.y1;
    context.clearRect(0, 0, width, this.height);
    context.drawImage(img,
      coordinates.x1, coordinates.y1,
      width, height,
      0, 0,
      this.width, this.height);
  }
}
