
declare const $: any;

export function initMaterialDesign(selector: string, includingCalendars: boolean = false) {
  setTimeout(() => {
    const params = {
      select: {
        selector: [selector + ' select:not([aria-hidden=true])']
      },
      text: {
        selector: selector +
        ' input:not([type=hidden]):not([type=checkbox]):not([type=radio]):not([type=file])' +
        ':not([type=button]):not([type=submit]):not([type=reset]):not(.no-label):not([role=listbox])'
      }
    };

    if (!includingCalendars) {
      params.text.selector += ':not(.calendar)';
    }

    $(selector).bootstrapMaterialDesign(params);
  });
}
