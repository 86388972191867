import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'specialChar'
})
export class SpecialCharPipe implements PipeTransform {

  transform(value: string): string {
    let newVal = value.replace(/[^a-zA-Z ]/g, ' ')
    return this.titleCase(newVal);
  }

  titleCase(str) {
    return str.charAt(0).toUpperCase() + str.substr(1).toLowerCase();
  }
}
