import {Subject} from 'rxjs';
import {Injectable} from '@angular/core';
import {Warning} from '../../../model/warning';
import {Message} from '../../../model/message';
import {Vehicle} from '../../../model/vehicle';

@Injectable({
  providedIn: 'root'
})
export class MapService {
  private _subject = new Subject<any>();
  private _warningSubject = new Subject<Warning>();
  private _messageSubject = new Subject<Message>();
  private _vehicleSubject = new Subject<Vehicle>();

  private _warningMapSubject = new Subject<Warning>();
  private _messageMapSubject = new Subject<Message>();


  constructor() {
  }

  newEvent(event) {
    this._subject.next(event);
  }

  get events$() {
    return this._subject.asObservable();
  }

  newUpdateWarningEvent(warning: Warning) {
    this._warningSubject.next(warning);
  }

  get updateWarningEvents$() {
    return this._warningSubject.asObservable();
  }

  newUpdateMessageEvent(message: Message) {
    this._messageSubject.next(message);
  }

  get updateMessageEvents$() {
    return this._messageSubject.asObservable();
  }

  newUpdateVehicleEvent(vehicle: Vehicle) {
    this._vehicleSubject.next(vehicle);
  }

  get updateVehicleEvents$() {
    return this._vehicleSubject.asObservable();
  }

  passWarningDataToMapEvent(warning: Warning) {
    this._warningMapSubject.next(warning);
  }

  get passWarningDataToMapEvents$() {
    return this._warningMapSubject.asObservable();
  }

  passMessageDataToMapEvent(message: Message) {
    this._messageMapSubject.next(message);
  }

  get passMessageDataToMapEvents$() {
    return this._messageMapSubject.asObservable();
  }
}
