import {Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {DomSanitizer, SafeUrl} from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  private _userUpdateSubject = new Subject<boolean>();

  constructor(private httpClient: HttpClient,
              private domSanitizer: DomSanitizer) {
  }

  newUserEvent(event) {
    this._userUpdateSubject.next(event);
  }

  get getUserEvents$() {
    return this._userUpdateSubject.asObservable();
  }

  getSecureImage(url: string): Observable<SafeUrl> {
    return this.httpClient
      .get(url, {responseType: 'blob'})
      .map(e => this.domSanitizer.bypassSecurityTrustUrl(URL.createObjectURL(e)));
  }
}
