import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Update} from '../../../model/update';
import {DownloadService} from './download.service';
import {saveAs} from 'file-saver';

@Injectable({
  providedIn: 'root'
})
export class UpdateService {
  updatePath = '/api/suc/zss/updates';

  constructor(private http: HttpClient,
              private downloadService: DownloadService) {
  }

  getUpdates(): Observable<Update[]> {
    return this.http.get<Update[]>(this.updatePath);
  }

  saveUpdate(update: Update): Observable<Update> {
    return this.http.post<Update>(`${this.updatePath}`, update);
  }

  editUpdate(update: Update): Observable<Update> {
    return this.http.put<Update>(`${this.updatePath}`, update);
  }

  deleteUpdate(id: number): Observable<any> {
    return this.http.delete(`${this.updatePath}/${id}`);
  }

  downloadUpdate(update: Update) {
    this.downloadService.getUpdate(update.id).subscribe((response: any) => {
      const blob: any = new Blob([response], {type: 'text/json; charset=utf-8'});
      saveAs(blob, update.name + '.zip');
    });
  }

  uploadFile(file: File, blob: Blob): Observable<Update> {
    const formData: FormData = new FormData();
    formData.append('file', file);
    formData.append('updateRequest', blob);
    return this.http.post<Update>(`${this.updatePath}/upload`, formData);
  }

}
