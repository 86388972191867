import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import * as reportActions from '../reports/reports.actions';
import {catchError, map, switchMap} from 'rxjs/operators';
import {of} from 'rxjs';
import {ReportService} from '../../submodules/logistics/services/report.service';
import {ReportWrapper} from '../../model/report-wrapper';

@Injectable()
export class ReportsEffects {
  fetchReports$ = createEffect(() =>
    this.actions$.pipe(
      ofType(reportActions.fetchReports),
      switchMap(() => this.reportService.getReports().pipe(
        map((wrapper: ReportWrapper) => reportActions.fetchReportsSuccess({
          reports: wrapper.reports,
          totalElements: wrapper.totalElements
        })),
        catchError(() => of(reportActions.fetchReportsError()))
      ))
    )
  );

  fetchReportsWithFiltering$ = createEffect(() =>
    this.actions$.pipe(
      ofType(reportActions.fetchReportsWithFiltering),
      switchMap(({filter}) => {
        return this.reportService.getReports(filter).pipe(
          map((wrapper: ReportWrapper) => reportActions.fetchReportsSuccess({
            reports: wrapper.reports,
            totalElements: wrapper.totalElements
          })),
          catchError(() => of(reportActions.fetchReportsError()))
        );
      })
    )
  );

  constructor(private actions$: Actions,
              private reportService: ReportService) {
  }
}
