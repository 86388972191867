import {createAction, props} from '@ngrx/store';
import {WarningFilter} from 'src/app/model/warning-filter';
import {Warning, WarningType} from '../../model/warning';

export const fetchWarnings = createAction('[Warnings] Fetch Warnings', props<{ warningType: WarningType }>());
export const fetchWarningsWithFiltering = createAction('[Warnings] Fetch Warnings With Filtering', props<{ filter: WarningFilter, warningType: WarningType }>());
export const fetchWarningsError = createAction('[Warnings] Fetch Warnings Error');
export const fetchWarningsSuccess = createAction(
  '[Warnings] Fetch Warnings Success',
  props<{ warnings: Warning[], warningType: WarningType, totalElements: number }>()
);
export const addWarningSuccess = createAction(
  '[Warnings] Add Warning Success',
  props<{ warning: Warning, warningType: WarningType }>()
);
export const updateWarningSuccess = createAction(
  '[Warnings] Update Warning Success',
  props<{ warning: Warning, warningType: WarningType }>()
);
export const deleteWarningSuccess = createAction(
  '[Warnings] Delete Warning Success',
  props<{ index: number, warningType: WarningType }>()
);
export const fetchAllWarnings = createAction('[Warnings] Fetch All Warnings');
export const fetchAllWarningsError = createAction('[Warnings] Fetch All Warnings Error');
export const fetchAllWarningsSuccess = createAction(
  '[Warnings] Fetch All Warnings Success',
  props<{ permanentWarnings: Warning[], timeWarnings: Warning[] }>()
);
