import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {FileSystemFileEntry, NgxFileDropEntry} from 'ngx-file-drop';
import {MessageService} from 'primeng/api';

@Component({
  selector: 'app-file-drop',
  templateUrl: './file-drop.component.html',
  styleUrls: ['./file-drop.component.less']
})
export class FileDropComponent implements OnInit {

  @ViewChild('fileDrop') fileDrop;

  @Input() accept = '*';

  @Input() displayLink = false;
  @Input() disabled = false;
  @Input() disabledDeleteButton = false;

  @Output() fileSelectionCallback = new EventEmitter();

  file: File;
  public files: NgxFileDropEntry[] = [];
  public displayError = false;

  constructor(private messageService: MessageService) {
  }

  ngOnInit(): void {
  }

  public handleFileDrop(files: NgxFileDropEntry[]) {
    if (!files[0]) {
      return;
    }

    if (files.length > 1) {
      this.messageService.add({severity: 'error', summary: 'Nie można dodać więcej niż 1 pliku'});
      return;
    }

    this.displayError = false;
    const draggedFile = files[0];

    if (draggedFile.fileEntry.isFile) {
      const fileEntry = draggedFile.fileEntry as FileSystemFileEntry;
      fileEntry.file((file: File) => {
        if (this.accept.match(file.type)) {
          this.file = file;
          const reader = new FileReader();
          reader.readAsDataURL(file);
          this.fileSelectionCallback.emit();
        } else {
          this.messageService.add({severity: 'error', summary: 'Format pliku nie jest obsługiwany'});
          return;
        }
      });
    }
  }

  deleteFile() {
    this.file = null;
  }

}
