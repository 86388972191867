import {BrowserModule} from '@angular/platform-browser';
import {APP_INITIALIZER, NgModule} from '@angular/core';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './components/app.component';
import {FormsModule} from '@angular/forms';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {LandingComponent} from './components/landing/landing.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {NavbarComponent} from './components/navbar/navbar.component';
import {CommonsModule} from './submodules/commons/commons.module';
import {CommonModule} from '@angular/common';
import {XhrInterceptor} from './interceptors/xhr-interceptor';
import {ParallaxDirective} from './components/landing/parallax.directive';
import {environment} from '../environments/environment';
import {AuthenticatedGuard} from './guards/authenticated-guard.service';
import {SessionExpiredInterceptor} from './interceptors/session-expired-interceptor';
import {ConnectionRefusedInterceptor} from './interceptors/connection-refused-interceptor';
import {EmptyComponent} from './components/empty/empty.component';
import {CookiePolicyComponent} from './components/cookie-policy/cookie-policy.component';
import {CookieInfoComponent} from './components/cookie-info/cookie-info.component';
import {StoreModule} from '@ngrx/store';
import {appReducer} from './store/app.reducer';
import {KeycloakAngularModule, KeycloakService} from 'keycloak-angular';
import {EffectsModule} from '@ngrx/effects';
import {EnumEffects} from './store/enums/enum.effects';
import {CarrierEffects} from './store/carriers/carrier.effects';
import {StoreDevtoolsModule} from '@ngrx/store-devtools';
import {WarningsEffects} from './store/warnings/warnings.effects';
import {TrainsEffects} from './store/trains/trains.effects';
import {ReportsEffects} from './store/reports/reports.effects';
import {UpdatesEffects} from './store/updates/updates.effects';
import {MessagesEffects} from './store/messages/messages.effects';
import {VehiclesEffects} from './store/vehicles/vehicles.effects';
import {TravelsEffects} from './store/travels/travels.effects';
import {ResultsEffects} from './store/results/results.effects';
import {InfrastructuresEffects} from './store/infrastructure/infrastructure.effects';
import {AvatarEffects} from './store/avatar/avatar.effects';

function initializeKeycloak(keycloak: KeycloakService) {
  return () =>
    keycloak.init({
      config: {
        url: environment.keycloakUrl,
        realm: 'wam',
        clientId: 'wam-web',
      }
    });
}

@NgModule({
  imports: [
    // Angular:
    BrowserModule,
    BrowserAnimationsModule,
    CommonModule,
    FormsModule,
    HttpClientModule,

    KeycloakAngularModule,

    // Project
    CommonsModule,
    AppRoutingModule,
    StoreModule.forRoot(appReducer, {}),
    EffectsModule.forRoot([EnumEffects, CarrierEffects, AvatarEffects, WarningsEffects, TrainsEffects, VehiclesEffects,
      MessagesEffects, UpdatesEffects, ReportsEffects, TravelsEffects, ResultsEffects, InfrastructuresEffects]),
    StoreDevtoolsModule.instrument({logOnly: environment.production})
  ],
  declarations: [
    AppComponent,
    LandingComponent,
    NavbarComponent,
    ParallaxDirective,
    EmptyComponent,
    CookiePolicyComponent,
    CookieInfoComponent

  ],
  providers: [
    AuthenticatedGuard,
    {provide: HTTP_INTERCEPTORS, useClass: ConnectionRefusedInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: XhrInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: SessionExpiredInterceptor, multi: true},
    {
      provide: APP_INITIALIZER,
      useFactory: initializeKeycloak,
      multi: true,
      deps: [KeycloakService],
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
