import {createEntityAdapter, EntityState} from '@ngrx/entity';
import {createReducer, on} from '@ngrx/store';
import * as resultActions from '../results/results.actions';
import {Result} from '../../model/result';

export interface ResultState extends EntityState<Result> {
  allResultsLoaded: boolean;
}

export const adapter = createEntityAdapter<Result>({
  selectId: (result: Result) => result.userId,
  sortComparer: null
});


export const initialResultState = adapter.getInitialState({
  allResultsLoaded: false
});

export const resultReducer = createReducer(
  initialResultState,

  on(resultActions.fetchResultsSuccess,
    (state, action) => adapter.setAll(action.results,
      {
        ...state,
        allResultsLoaded: true
      }))
);

export const {
  selectAll
} = adapter.getSelectors();
