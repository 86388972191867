import {KeycloakProfile, KeycloakTokenParsed} from 'keycloak-js';
import {KeycloakUser} from '../../../model/keycloak-user';

export function mapTokenToKeycloakUser(token: KeycloakTokenParsed): KeycloakUser {
  if (!token) {
    return null;
  }

  const user: KeycloakUser = {
    id: token['sub'],
    email: token['email'],
    username: token['preferred_username'],
    firstName: token['given_name'],
    lastName: token['family_name'],
    enabled: true,
    realmRoles: token.realm_access.roles,
    attributes: {}
  };

  mapTokenAttributes(user, token);
  return user;
}

export function mapTokenAttributes(user: KeycloakUser, token: KeycloakTokenParsed): void {
  const attributes = token['attributes'];
  if (!!attributes) {
    Object.keys(attributes).forEach(key => {
      user.attributes[key] = [];
      user.attributes[key].push(attributes[key]);
    });
  }
}

export function updateUserProfile(user: KeycloakUser, updatedProfile: KeycloakProfile): void {
  user.firstName = updatedProfile.firstName;
  user.lastName = updatedProfile.lastName;
  user.email = updatedProfile.email;
  user.attributes = updatedProfile['attributes'];
}

export function getAvatarUrl(user: KeycloakUser): string {
  if (!checkIfAvatarExist(user)) {
    return null;
  }
  return `${window.location.protocol}//${window.location.host}/api/${user.attributes['avatar_url'][0]}`;
}

export function checkIfAvatarExist(user: KeycloakUser): boolean {
  return checkIfUserAttributeExist(user, 'avatar_url');
}

export function checkIfUserAttributeExist(user: KeycloakUser, key: string): boolean {
  return !!user.attributes && !!user.attributes[key];
}

export function checkIfAttributeExist(attributes: Map<string, string[]>, key: string): boolean {
  if (attributes.size === 0) {
    return false;
  }
  return !!attributes.get(key);
}

export function updateAttribute(attributes: Map<string, string[]>, key: string, value: string): void {
  if (!!value) {
    if (checkIfAttributeExist(attributes, key)) {
      attributes.set(key, [value]);
    } else {
      attributes[key] = [];
      attributes[key].push(value);
    }
  }
}

export function fillUserAttribute(user: KeycloakUser, key: string, value: string): void {
  if (!user.attributes[key]) {
    user.attributes[key] = [];
    user.attributes[key].push(value);
  } else {
    user.attributes[key][0] = value;
  }
}

export function initUserAttributes(user: KeycloakUser): void {
  if (!user.attributes) {
    user.attributes = {};
  }
}
