import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {HttpErrorResponse} from '@angular/common/http';
import {AbstractControl} from '@angular/forms';

@Component({
  selector: 'app-messages',
  templateUrl: './messages.component.html',
  styleUrls: ['./messages.component.css']
})
export class MessagesComponent implements OnInit, OnChanges {

  @Input() httpErrorResponse: HttpErrorResponse = null;
  @Input() controls: AbstractControl[] = [];

  @Input()
  set control(control: AbstractControl) {
    this.controls = [control];
  }

  get control(): AbstractControl {
    return this.controls[0];
  }

  @Input() propertyName: string;
  @Input() errorClass = 'invalid-feedback';


  constructor() {
  }

  ngOnInit() {
  }

  checkPropertyName(name: string): boolean {
    if (this.propertyName == null && this.controls.length === 0) {
      return true;
    }

    if (this.propertyName) {
      return name === this.propertyName;
    }

    return this.controls.map(c => Object.keys(c.parent.controls).find(key => c.parent.controls[key] === c) === name)
      .reduce((a, b) => a || b, false);
  }

  ngOnChanges(changes: SimpleChanges) {
    this.controls.forEach(control => {
      if (control) {
        control.setErrors(null);
      }
    });

    if (!changes['httpErrorResponse'] || !this.httpErrorResponse) {
      return;
    }

    if (this.httpErrorResponse.error) {
      for (const error of this.httpErrorResponse.error.errors) {
        if (this.checkPropertyName(error.field)) {
          this.controls.forEach(c => c.setErrors({'incorrect': true}));
        }
      }
    }
  }
}
