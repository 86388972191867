import {Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {AuthService} from '../../services/auth.service';
import {HttpClient, HttpErrorResponse, HttpParams} from '@angular/common/http';
import {NgForm} from '@angular/forms';
import {ValidationHelperService} from '../../services/validation-helper.service';
import {MessageService} from 'primeng/api';
import {KeycloakService} from 'keycloak-angular';
import {KeycloakProfile, KeycloakTokenParsed} from 'keycloak-js';
import {environment} from '../../../../../environments/environment';
import {Store} from '@ngrx/store';
import {AppState} from '../../../../store/app.reducer';
import {selectCarrierList} from '../../../../store/carriers/carrier.reducer';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {initialUser, KeycloakUser} from '../../../../model/keycloak-user';
import {fillUserAttribute, getAvatarUrl, initUserAttributes, mapTokenToKeycloakUser, updateUserProfile} from '../../utils/keycloak-utils';
import {DialogComponent} from '../dialog/dialog.component';
import {DomSanitizer} from '@angular/platform-browser';
import {CustomCropperComponent} from '../custom-cropper/custom-cropper.component';
import {UserService} from '../../../logistics/services/user.service';
import {fetchAvatarImage} from '../../../../store/avatar/avatar.actions';

@Component({
  selector: 'app-profile-manager',
  templateUrl: './profile-manager.component.html',
  styleUrls: ['./profile-manager.component.less']
})
export class ProfileManagerComponent implements OnInit, OnDestroy {

  @Output() onCancel = new EventEmitter();
  @ViewChild('avatarDialog') avatarDialog: DialogComponent;
  @ViewChild('imageCropper') imageCropper: CustomCropperComponent;

  user: KeycloakUser = initialUser;
  operatorUser = {carrier: null};
  token: KeycloakTokenParsed = this.keycloakService.getKeycloakInstance().tokenParsed;

  carrierList = [];

  httpErrorResponse: HttpErrorResponse;
  spinner = false;
  ngUnsubscribe = new Subject();

  constructor(private authService: AuthService,
              private store: Store<AppState>,
              private httpClient: HttpClient,
              private validationHelper: ValidationHelperService,
              private domSanitizer: DomSanitizer,
              private messageService: MessageService,
              private keycloakService: KeycloakService,
              private userService: UserService) {
    this.userService.getUserEvents$.subscribe(() => {
      this.keycloakService.loadUserProfile(true).then((profile: KeycloakProfile) => {
        updateUserProfile(this.user, profile);
        this.store.dispatch(fetchAvatarImage({avatarUrl: getAvatarUrl(this.user)}));
      });
    });
  }

  ngOnInit() {
    this.user = mapTokenToKeycloakUser(this.token);
    if (this.authService.checkIfContainsRoles(['OPERATOR'])) {
      this.updateOperatorUserData();
    }
  }

  saveData(avatarChanged: boolean = false): void {
    this.spinner = true;
    this.authService.updateCurrentUser(this.user)
      .finally(() => {
        this.spinner = false;
        this.imageCropper.clearCropper();
      })
      .subscribe(() => {
        this.userService.newUserEvent(avatarChanged);
        this.messageService.add({severity: 'success', summary: 'Profil użytkownika zaktualizowany'});
      }, () => {
        this.messageService.add({severity: 'error', summary: 'Aktualizacja nie powiodła się'});
      });
  }

  cancel() {
    this.resetInfo();
    this.onCancel.emit();
  }

  private resetInfo() {
    this.httpErrorResponse = null;
  }

  submitUserData(form: NgForm) {
    this.validationHelper.ifValidThen(form, () => {
      this.saveData(true);
    });
  }

  triggerPasswordChangeFlow() {
    window.location.href = this.buildPasswordChangeFlowUri();
  }

  buildPasswordChangeFlowUri() {
    const params = new HttpParams()
      .set('response_type', 'code')
      .set('client_id', this.token['azp'])
      .set('redirect_uri', window.location.origin + '/run')
      .set('kc_action', 'UPDATE_PASSWORD');

    return environment.keycloakUrl + 'realms/wam/protocol/openid-connect/auth?' + params.toString();
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  updateOperatorUserData() {
    this.store.select(selectCarrierList).pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(carrierList => {
        this.carrierList = carrierList;
        const carrierId = parseInt(this.keycloakService.getKeycloakInstance().tokenParsed['attributes']['carrier_id']);
        if (this.carrierList.length > 0) {
          this.operatorUser.carrier = this.carrierList.find(carrier => carrier.id === carrierId).name;
        }
      });
  }

  saveUserAvatar(fileChanged: boolean) {
    initUserAttributes(this.user);

    if (fileChanged) {
      fillUserAttribute(this.user, 'avatar_url', this.imageCropper.sourceImage.replace(/^data:(.*,)?/, ''));
    } else {
      fillUserAttribute(this.user, 'avatar_url', this.user.attributes['avatar_url'][0]);
    }

    fillUserAttribute(this.user, 'avatar_info', JSON.stringify(this.imageCropper.currentAvatarInfo));
    this.saveData(true);
  }
}
