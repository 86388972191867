import {Directive, ElementRef, Input, OnInit} from '@angular/core';
import {AuthService} from '../services/auth.service';

@Directive({
  selector: '[appRolesAllowed]'
})
export class RolesAllowedDirective implements OnInit {

  roles: string[];

  @Input() set appRolesAllowed(roles: string[]) {
    this.roles = roles;
  }

  constructor(private elementRef: ElementRef,
              private authService: AuthService) {
  }

  ngOnInit(): void {
    if (!this.authService.checkIfContainsRoles(this.roles)) {
      this.elementRef.nativeElement.style.display = 'none';
    }
  }


}
