import {Directive, ElementRef, HostListener, Input, OnInit} from '@angular/core';

@Directive({
  selector: '[appParallax]'
})
export class ParallaxDirective implements OnInit {

  @Input('ratio') ratio = 1;
  initialTop = 0;

  constructor(private eleRef: ElementRef) {
  }

  ngOnInit(): void {
    this.initialTop = this.eleRef.nativeElement.getBoundingClientRect().top;
  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll() {
    this.eleRef.nativeElement.style['background-position-y'] = (this.initialTop - (window.scrollY * this.ratio)) + 'px';
  }
}
