import {Warning, WarningType} from '../../model/warning';
import {Action, createFeatureSelector, createReducer, createSelector, on} from '@ngrx/store';
import * as warningActions from './warnings.actions';

export interface WarningState {
  permanentWarnings: Warning[];
  timeWarnings: Warning[];
  totalElements: number;
}

const initialState: WarningState = {
  permanentWarnings: [],
  timeWarnings: [],
  totalElements: null
};

const _warningReducer = createReducer(
  initialState,
  on(warningActions.fetchWarningsSuccess,
    (state, {warnings, warningType, totalElements}) => ({
      ...state,
      permanentWarnings: warningType === WarningType.permanent ? warnings : [...state.permanentWarnings],
      timeWarnings: warningType === WarningType.time ? warnings : [...state.timeWarnings],
      totalElements: totalElements
    })),
  on(warningActions.addWarningSuccess,
    (state, {warning, warningType}) => ({
      ...state,
      permanentWarnings: warningType === WarningType.permanent ? [...state.permanentWarnings, warning] : [...state.permanentWarnings],
      timeWarnings: warningType === WarningType.time ? [...state.timeWarnings, warning] : [...state.timeWarnings]
    })),
  on(warningActions.updateWarningSuccess,
    (state, {warning, warningType}) => ({
      ...state,
      permanentWarnings: warningType === WarningType.permanent ? state.permanentWarnings.map(w => w.id === warning.id ? warning : w) : [...state.permanentWarnings],
      timeWarnings: warningType === WarningType.time ? state.timeWarnings.map(w => w.id === warning.id ? warning : w) : [...state.timeWarnings]
    })),
  on(warningActions.deleteWarningSuccess,
    (state, {index, warningType}) => ({
      ...state,
      permanentWarnings: warningType === WarningType.permanent ? state.permanentWarnings.filter(w => w.id !== index) : [...state.permanentWarnings],
      timeWarnings: warningType === WarningType.time ? state.timeWarnings.filter(w => w.id !== index) : [...state.timeWarnings]
    })),
  on(warningActions.fetchAllWarningsSuccess,
    (state, {permanentWarnings, timeWarnings}) => ({
      ...state,
      permanentWarnings: permanentWarnings,
      timeWarnings: timeWarnings
    })),
);

export function warningReducer(state: WarningState, action: Action) {
  return _warningReducer(state, action);
}

const selectWarningState = createFeatureSelector<WarningState>('warnings');

export const selectAllWarnings = createSelector(selectWarningState, (state: WarningState) => state);

export const selectWarnings = (warningType: WarningType) => createSelector(selectWarningState,
  (state: WarningState) => {
    if (warningType === WarningType.permanent) {
      return state.permanentWarnings;
    } else {
      return state.timeWarnings;
    }
  });

export const getWarningState = createSelector(selectWarningState, (state: WarningState) => state);
