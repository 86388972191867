export interface Update {
  id: number;
  name: string;
  installationImportance: string;
  installationType: string;
  version: string;
  createdDate: Date;
  installationDate: Date;
  numberOfDownloads: number;
  changeList: string[];
}

export function compareUpdates(update1: Update, update2: Update) {
  const compare = new Date(update1.createdDate).getTime() - new Date(update2.createdDate).getTime();
  return compare < 0 ? 1 : compare > 0 ? -1 : 0;
}
