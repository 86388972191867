import {createAction, props} from '@ngrx/store';
import {Train} from '../../model/train';

export const fetchTrains = createAction('[Trains] Fetch Train');
export const fetchTrainsError = createAction('[Trains] Fetch Train Error');
export const fetchTrainsSuccess = createAction(
  '[Trains] Fetch Trains Success',
  props<{ trains: Train[] }>()
);
export const addTrainsSuccess = createAction(
  '[Trains] Add Train Success',
  props<{ train: Train }>()
);
export const updateTrainsSuccess = createAction(
  '[Trains] Update Train Success',
  props<{ train: Train }>()
);
export const deleteTrainSuccess = createAction(
  '[Trains] Delete Train Success',
  props<{ index: number }>()
);

