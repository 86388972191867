import {Directive, ElementRef, Input, OnDestroy, OnInit} from '@angular/core';
import {TooltipService} from '../services/tooltip.service';

@Directive({
  selector: '[appTooltipped]'
})
export class TooltippedDirective implements OnInit, OnDestroy {

  @Input() tooltipName: string;
  @Input() tooltipGroup = 'default';

  constructor(private element: ElementRef<HTMLHtmlElement>, private tooltipService: TooltipService) {
  }

  ngOnInit(): void {
    this.tooltipService.registerTooltippedElement(this.element, this.tooltipName, this.tooltipGroup);
  }

  ngOnDestroy(): void {
    this.tooltipService.unregisterTooltippedElement(this.tooltipName, this.tooltipGroup);
  }

}
