import {createAction, props} from '@ngrx/store';
import {Report} from '../../model/report';
import {ReportFilter} from '../../model/report-filter';

export const fetchReports = createAction('[Reports] Fetch Report');
export const fetchReportsWithFiltering = createAction('[Reports] Fetch Reports With Filtering', props<{ filter: ReportFilter }>());
export const fetchReportsError = createAction('[Reports] Fetch Report Error');
export const fetchReportsSuccess = createAction(
  '[Reports] Fetch Reports Success',
  props<{ reports: Report[], totalElements: number }>()
);
export const addReportsSuccess = createAction(
  '[Reports] Add Report Success',
  props<{ reports: Report[], totalElements: number }>()
);
export const updateReportsSuccess = createAction(
  '[Reports] Update Report Success',
  props<{ report: Report }>()
);
export const deleteReportsSuccess = createAction(
  '[Reports] Delete Reports Success',
  props<{ index: number }>()
);
