import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import 'rxjs/add/operator/map';

@Injectable({
  providedIn: 'root'
})
export class ContactUsService {
  private contactUsUrl = '/api/suc/contact-us';

  constructor(private http: HttpClient) {
  }

  sendMessage(name: string, email: string, message: string, formId: string) {
    const body = {name: name, email: email, message: message, formId: formId};
    return this.http.post(this.contactUsUrl, body);
  }

}
