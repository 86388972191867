import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import * as travelActions from '../travels/travels.actions';
import {catchError, map, switchMap} from 'rxjs/operators';
import {of} from 'rxjs';
import {TravelService} from '../../submodules/logistics/services/travel.service';
import {Travel} from '../../model/travel';

@Injectable()
export class TravelsEffects {
  fetchTravels$ = createEffect(() =>
    this.actions$.pipe(
      ofType(travelActions.fetchTravels),
      switchMap(() => this.travelService.getTravels().pipe(
        map((travels: Travel[]) => travelActions.fetchTravelsSuccess({travels: travels})),
        catchError(() => of(travelActions.fetchTravelsError()))
      ))
    )
  );

  constructor(private actions$: Actions,
              private travelService: TravelService) {
  }
}
