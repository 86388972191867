import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {ImageCroppedEvent, ImageCropperComponent} from 'ngx-image-cropper';
import {KeycloakUser} from '../../../../model/keycloak-user';
import {AvatarInfo} from '../../../../model/avatar-info';
import {MessageService} from 'primeng/api';
import {HttpClient} from '@angular/common/http';
import {FileInputComponent} from '../file-input/file-input.component';
import {getAvatarUrl} from '../../utils/keycloak-utils';

@Component({
  selector: 'app-custom-cropper',
  templateUrl: './custom-cropper.component.html',
  styleUrls: ['./custom-cropper.component.less']
})
export class CustomCropperComponent implements OnInit {

  @ViewChild('cropper') imageCropper: ImageCropperComponent;
  @ViewChild('fileInput') fileInput: FileInputComponent;

  @Input() user: KeycloakUser;
  @Output() finished = new EventEmitter<boolean>();

  editMode = false;

  croppedImage: any = '';
  imageChangedEvent: any = '';
  imageBase64: string;
  currentAvatarInfo: AvatarInfo;

  sourceImage = '';
  fileChanged = false;


  constructor(private messageService: MessageService,
              private httpClient: HttpClient) {
  }

  ngOnInit(): void {
  }

  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
    this.fileChanged = true;
    const reader = new FileReader();
    reader.readAsDataURL(this.fileInput.file);
    reader.onload = () => {
      this.sourceImage = reader.result as string;
    };
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
    this.currentAvatarInfo = {
      imagePosition: event.imagePosition,
      cropperPosition: event.cropperPosition
    };
  }

  loadImageFailed() {
    this.messageService.add({severity: 'error', summary: 'Nie udało się załadować obrazu'});
  }

  clearCropper() {
    if (this.fileInput && this.fileInput.file) {
      this.fileInput.file = null;
    }

    this.imageCropper.resetCropperPosition();
    this.imageBase64 = null;
    this.imageChangedEvent = '';
    this.croppedImage = '';
    this.sourceImage = '';
    this.fileChanged = false;
  }

  initCropper(edit: boolean) {
    this.editMode = edit;
    if (!!this.imageCropper) {
      this.editMode ? this.initializeImageManipulation() : this.editMode = false;
    }
  }

  initializeImageManipulation() {
    if (!!this.user.attributes['avatar_url'] && !!this.user.attributes['avatar_info']) {
      this.setCropperImage(getAvatarUrl(this.user)).then(() => {
        this.imageLoaded();
      });
    }
  }


  async setCropperImage(url: string) {
    const request$ = this.httpClient.get(url, {responseType: 'blob'});
    await request$.subscribe((e: Blob) => {
      const reader = new FileReader();
      reader.readAsDataURL(e);
      reader.onload = () => {
        this.imageBase64 = reader.result as string;
      };
    });
  }

  imageLoaded() {
    if (!!this.user.attributes['avatar_info'] && this.editMode) {
      setTimeout(() => {
        const avatarInfo: AvatarInfo = JSON.parse(this.user.attributes['avatar_info'][0]);
        this.imageCropper.cropper = avatarInfo.cropperPosition;
        this.imageCropper.crop();
      }, 1);
    }
  }
}
