export interface KeycloakUser {
  id: string;
  enabled: boolean;
  email?: string;
  username?: string;
  firstName?: string;
  lastName?: string;
  lastAccess?: Date;
  attributes: { [key: string]: string[] };
  realmRoles: string[];
}

export const initialUser: KeycloakUser = {
  id: '',
  enabled: true,
  email: '',
  username: '',
  firstName: '',
  lastName: '',
  attributes: {},
  realmRoles: [],
};

