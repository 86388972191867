import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class DownloadService {

  documentPath = '/api/suc/cbd/documents';
  reportPath = '/api/suc/reports';
  updatePath = '/api/suc/zss/updates/download';

  constructor(private httpClient: HttpClient) {
  }

  getDocument(bid: string): Observable<Blob> {
    return this.httpClient.get(`${this.documentPath}/${bid}`, {
      responseType: 'blob'
    });
  }

  getReport(id: number): Observable<Blob> {
    return this.httpClient.get(`${this.reportPath}/${id}`, {
      responseType: 'blob'
    });
  }

  getUpdate(id: number): Observable<Blob> {
    return this.httpClient.get(`${this.updatePath}/${id}`, {
      responseType: 'blob'
    });
  }
}
