import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import * as updatesActions from '../updates/updates.actions';
import {catchError, map, switchMap} from 'rxjs/operators';
import {of} from 'rxjs';
import {UpdateService} from '../../submodules/logistics/services/update.service';
import {Update} from '../../model/update';

@Injectable()
export class UpdatesEffects {

  fetchUpdates$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updatesActions.fetchUpdates),
      switchMap(() => this.updateService.getUpdates().pipe(
        map((updates: Update[]) => updatesActions.fetchUpdatesSuccess({
          updates
        })),
        catchError(() => of(updatesActions.fetchUpdatesError()))
      ))
    )
  );

  constructor(private actions$: Actions,
              private updateService: UpdateService) {
  }
}
