import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.less']
})
export class NavbarComponent implements OnInit {

  public constructor(private router: Router) {
  }

  ngOnInit() {
  }

  public isActive(url): boolean {
    return this.router.url === url;
  }
}
