import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';

@Component({
  selector: 'app-cookie-info',
  templateUrl: './cookie-info.component.html',
  styleUrls: ['./cookie-info.component.less']
})
export class CookieInfoComponent implements OnInit {

  private readonly COOKIE_ACCEPTED_KEY = 'cookies_accepted';

  constructor(private router: Router) {
  }

  ngOnInit() {
  }

  get visible(): boolean {
    return localStorage.getItem(this.COOKIE_ACCEPTED_KEY) === null;
  }

  get onMap() {
    return this.router.url === '/app';
  }

  hide() {
    localStorage.setItem(this.COOKIE_ACCEPTED_KEY, 'true');
  }

  cookiePolicy() {
    this.router.navigate(['cookie-policy']);
  }
}
